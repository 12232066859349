import React from "react";
import { Link } from "react-router-dom";
import DoneIcon from '../../assets/Icons/Done.png';
import Logo from '../../assets/Images/Logo.png';
import { Button, Box, Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

export default function ResetLinkSent() {
    const { t } = useTranslation(); // Use the hook to access translations

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                bgcolor: '#f5f5f5',
                padding: '20px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    bgcolor: '#fff',
                    padding: '40px',
                    borderRadius: '10px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <img src={Logo} alt="logo-img" style={{ marginBottom: '20px', width:'100px', height:'100px' }} />
                <img src={DoneIcon} alt="done-icon" style={{ marginBottom: '20px', width:'100px', height:'100px' }} />
                <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
                    {t('resetLinkSent.title')}
                </Typography>
                <Typography sx={{ marginBottom: '20px', color: '#555' }}>
                    {t('resetLinkSent.description')}
                </Typography>
                <Button
                    component={Link}
                    to="/login"
                    sx={{
                        padding: '10px 20px',
                        fontSize: '14px',
                        fontWeight: 400,
                        borderRadius: '10px',
                        backgroundColor: '#1a477f',
                        color: 'white',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#163D6C',
                        },
                    }}
                >
                    {t('resetLinkSent.backToLogin')}
                </Button>
            </Box>
            <Box
                sx={{
                    marginTop: '20px',
                    textAlign: 'center',
                    color: '#555',
                }}
            >
                <Typography component="p" sx={{ fontSize: '14px' }}>
                    <Link to="/terms-Condition" style={{ color: '#1a477f', textDecoration: 'none' }}>
                        {t('resetLinkSent.termsOfUse')}
                    </Link>
                    &nbsp;|&nbsp;
                    <Link to="/privacy-policy" style={{ color: '#1a477f', textDecoration: 'none' }}>
                        {t('resetLinkSent.privacyPolicy')}
                    </Link>
                </Typography>
            </Box>
        </Container>
    );
}
