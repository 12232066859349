import React, { useState } from "react";
import Logo from "../../../assets/Images/Logo.png";
import FieldRegister from "../Auth/FieldRegister";
import {
  textValidator,
  emailValidator,
  validateConfirmPassword,
  validatePassword,
  maxCharactersValidator
} from "../../../Validators/validator";
import RegisterImg from "../../../assets/Images/Register.png";
import PasswordField from "./PasswordField";
import userIcon from "../../../assets/Icons/user.png";
import outMsg from "../../../assets/Icons/outMsg.png";
import { registerUser } from "../../../API/apiService";
import { initialUserRegister } from "../../../Models/user";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getCurrentUser } from "../../../API/apiService";

export default function RegisterForm() {
  const { t } = useTranslation();
  const [registerData, setRegisterData] = useState(initialUserRegister);
  const [validationErrors, setValidationErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(getCurrentUser() !== null){
      navigate('/');
    }
  },[])

  const handleInputChange = (event, parent, child) => {
    event.preventDefault();
    setValidationErrors((prevState) => ({
      ...prevState,
      [child]: "",
    }));

    const value = event.target.value;
    setRegisterData((prevState) => ({
      ...prevState,
      [child]: value,
    }));
  };

  const handleUserRegister = async (event) => {
    event.preventDefault();
    let errors = {};
    errors.name = textValidator(registerData.name);
    errors.name += maxCharactersValidator(registerData.name, 25);
    errors.lastName = textValidator(registerData.lastName);
    errors.lastName += maxCharactersValidator(registerData.lastName, 25);
    errors.email = emailValidator(registerData.email);
    errors.email += maxCharactersValidator(registerData.email, 250);
    errors.password = validatePassword(registerData.password);
    errors.confirmPassword = validateConfirmPassword(
      registerData.confirmPassword,
      registerData.password
    );

    setValidationErrors(errors);

    const hasErrors = Object.values(errors).some((error) => error !== "");
    if (!hasErrors) {
      try {
        setLoading(true);
        const response = await registerUser(registerData);

        if (response.success) {
          setRegisterData(initialUserRegister);
          localStorage.setItem('emailConfirm', registerData.email);
          navigate("/confirm-email");
        } 
      } catch (error) {
  
      } finally {
        setLoading(false);
      }
    } else {
      toast.error(t('register.validationError'));
      setLoading(false);
    }
  };

  return (
    <div className="register-container">
      <div className="regiter-content">
        <div>
          <img src={Logo} alt="logo-img" />
        </div>
        <div className="register-form">
          <div className="register-form-content">
            <h3>{t('register.createAccount')}</h3>
            <FieldRegister
              label={t('register.firstName')}
              type="text"
              parent="name"
              fieldName="name"
              icon={userIcon}
              showAdornment={false}
              maxCharacters={25}
              validator={textValidator}
              onChange={handleInputChange}
            />
            <FieldRegister
              label={t('register.lastName')}
              type="text"
              parent="surname"
              fieldName="surname"
              icon={userIcon}
              showAdornment={false}
              validator={textValidator}
              maxCharacters={25}
              onChange={handleInputChange}
            />
            <FieldRegister
              label={t('register.email')}
              type="email"
              parent="email"
              fieldName="email"
              icon={outMsg}
              showAdornment={false}
              validator={emailValidator}
              maxCharacters={250}
              onChange={handleInputChange}
            />

            <PasswordField
              label={t('register.password')}
              fieldName="password"
              parent="password"
              validator={validatePassword}
              onChange={handleInputChange}
              password={initialUserRegister.password}
              errorForm={validationErrors.password}
            />
            <PasswordField
              label={t('register.confirmPassword')}
              fieldName="confirmPassword"
              parent="password"
              validator={(value, compareValue) =>
                validateConfirmPassword(value, compareValue)
              }
              compareValue={registerData.password}
              onChange={handleInputChange}
              errorForm={validationErrors.confirmPassword}
            />
            <div className="account-link">
              <Button
                variant="contained"
                color="inherit"
                sx={{
                  backgroundColor: "#163D6C",
                  color: "white",
                  padding: 1,
                  textTransform: "none",
                  width: '100%',
                  borderRadius: 4.5,
                  "&:hover": {
                    backgroundColor: "#122c51",
                  },
                }}
                onClick={handleUserRegister}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={24} /> : null}
              >
                {loading ? t('register.processing') : t('register.signUp')}
              </Button>
              <div className="link-sigIn">
                <p>
                  {t('register.alreadyHaveAccount')}
                  <Link className="link" to="/login">
                    <span className="singin-span"> {t('register.signIn')}</span>
                  </Link>
                </p>
              </div>
              <div>
                <p className="p-terms">
                  <Link className="link" to="/terms-Condition">
                    <span className="term-policy-link">{t('register.termsOfUse')}</span>
                  </Link>
                  <span> &nbsp; | &nbsp; </span>
                  <Link className="link" to="/privacy-policy">
                    <span className="term-policy-link">{t('register.privacyPolicy')}</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="register-image">
            <img src={RegisterImg} alt="register-img" />
          </div>
        </div>
      </div>
    </div>
  );
}
