import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import toast from "react-hot-toast";
import {
  subscribCheckout,
  getCurrentUser,
  getUserActiveSubscriptions,
} from "../../API/apiService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SubscriptionOptions = ({serviceType}) => {
  const { t } = useTranslation();
  const [activeSubscription, setActiveSubscriptions] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});

  const navigate = useNavigate();

  const fetchSubscriptions = async () => {
    try {
      const currentUser = getCurrentUser();
      if (currentUser === null) return;
      const response = await getUserActiveSubscriptions();
      setActiveSubscriptions(response.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    fetchSubscriptions();
    if (query.get("success")) {
      toast.success(t("subscription.successMessage"), { duration: 6000 });
    } else if (query.get("canceled")) {
      //toast.error(t('subscription.errorMessage'));
    }
  }, [t]);

  const isScanPlanActive = () => {
    if (!activeSubscription) return false;
    const isActive = activeSubscription?.filter((s) => s.type === "scan");
    return isActive?.length > 0 && isActive[0].isActive;
  };

  const isDigitalPlanActive = () => {
    if (!activeSubscription) return false;
    const isActive = activeSubscription?.filter((s) => s.type === "digital");
    return isActive?.length > 0 && isActive[0].isActive;
  };

  const isPlanActive = (serviceType, planName) => {
    if (!activeSubscription) return false;

    // Adjusting the filter to check 's.planType' and 's.type'
    const scanSubs = activeSubscription?.filter(
      (s) =>
        s.type === serviceType && s.isActive === true && s.planType === planName
    );

    if (scanSubs.length > 0) {
      return true;
    }

    return false;
  };

  const checkIfPlanCanBeAdded = (serviceType, planName) => {
    const subsActive = activeSubscription?.filter(
      (s) => s.type === serviceType && s.isActive === true
    );

    if (subsActive.length === 0) return true;

    const isThisPlanActive = subsActive.filter((s) => s.planType === planName);

    if (isThisPlanActive?.length > 0) {
      return true;
    }

    return false;
  };

  const handleSubscribe = async (e, serviceType, planType) => {
    e.preventDefault();
    if (!serviceType || !planType) {
      toast.error(t("subscription.refreshError"));
      return;
    }
      setLoadingStates((prev) => ({ ...prev, [planType]: true }));
    const userLogged = getCurrentUser();
    if (userLogged === null) {
      toast.error(t("subscription.loginError"));
      navigate("/login");
      return;
    }

    if (checkIfPlanCanBeAdded(serviceType, planType) === false) {
      toast.error(
        t("subscription.alreadyExist")
      );
      setLoadingStates((prev) => ({ ...prev, [planType]: false }));
      return;
    }

    try {
      const payload = {
        userId: getCurrentUser()["id"],
        serviceType: serviceType,
        projectIds: [""],
        planType: planType,
      };

      const response = await subscribCheckout(payload);
      if (response.success) {
        window.location.href = response.data;
      }
    } catch (error) {}
    finally{
      setLoading(false);
    }
  };

  const SubscriptionCard = ({
    title,
    price,
    details,
    serviceType,
    planType,
    loading,
    isActive = false,
  }) => (
    <Card
      variant="outlined"
      sx={{
        maxWidth: "100%",
        minWidth: 300,
        mr: 2,
        boxShadow: 4,
        borderRadius: 3,
        m: 2,
        p: { xs: 2, sm: 5 },
        boxSizing: "border-box",
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{
            wordWrap: "break-word",
            textAlign: "center",
            overflowWrap: "break-word",
          }}
        >
          {title}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <MonetizationOnIcon sx={{ mt: 2 }} color="primary" fontSize="large" />
          <Typography
            variant="h4"
            color="primary"
            sx={{ ml: 1, wordWrap: "break-word", overflowWrap: "break-word" }}
          >
            €{price}/{t("subscription.month")}
          </Typography>
        </Box>
        <List sx={{ paddingRight: { xs: 0, sm: 2 }, textAlign: "start" }}>
          {details.map((detail, index) => (
            <ListItem
              key={index}
              disableGutters
              sx={{ display: "flex", alignItems: "flex-start" }}
            >
              <ListItemIcon>
                <CheckCircleIcon sx={{ mt: 1, ml: -0 }} color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={detail}
                sx={{
                  paddingRight: { xs: 20, md: 10, lg: 0 },
                  textAlign: "start",
                  marginLeft: "-15px",
                  marginTop: 1,
                }}
              />
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          sx={{ mt: 2 }}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={24} /> : null}
          onClick={(e) => handleSubscribe(e, serviceType, planType)}
        >
          {loading ? t("priceScan.processing") : (isActive ? t("subscription.activePlan") : t("subscription.subscribeNow"))}

        </Button>
      </CardContent>
    </Card>
  );

  return (
    <Container
      sx={{ mt: { xs: 10, sm: 20 }, pb: { xs: 10, sm: 0 }, minHeight: "90vh" }}
    >
      <Typography
        color="#163D6C"
        variant="h4"
        component="h1"
        gutterBottom
        align="center"
      >
        {t("subscription.title")}
      </Typography>

      <Box
        sx={{
          display: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {serviceType === 'scan' && (
        <>
          <Typography variant="h5" sx={{ textAlign: "center", mt: 5, mb: 0.5 }}>
            {t("subscription.scanPlan")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <SubscriptionCard
              title={t("subscription.scanningTitle")}
              price="16.5"
              serviceType="scan"
              planType="s1"
              isActive={isPlanActive("scan", "s1")}
              loading={loadingStates["s1"] || false}
              details={[
                t("subscription.nrProjects") + " 3",
                t("subscription.scanningDetail1"),
                t("subscription.scanningDetail2"),
              ]}
            />

            <SubscriptionCard
              title={t("subscription.scanningTitle")}
              price="50"
              serviceType="scan"
              planType="s2"
              isActive={isPlanActive("scan", "s2")}
              loading={loadingStates["s2"] || false}
              details={[
                t("subscription.nrProjects") + " 10",
                t("subscription.scanningDetail1"),
                t("subscription.scanningDetail2"),
              ]}
            />

            <SubscriptionCard
              title={t("subscription.scanningTitle")}
              price="90"
              serviceType="scan"
              planType="s3"
              isActive={isPlanActive("scan", "s3")}
              loading={loadingStates["s3"] || false}
              details={[
                t("subscription.nrProjects") + " 20",
                t("subscription.scanningDetail1"),
                t("subscription.scanningDetail2"),
              ]}
            />

            <SubscriptionCard
              title={t("subscription.scanningTitle")}
              price="160"
              serviceType="scan"
              planType="s4"
              isActive={isPlanActive("scan", "s4")}
              loading={loadingStates["s4"] || false}
              details={[
                t("subscription.nrProjects") + " 40",
                t("subscription.scanningDetail1"),
                t("subscription.scanningDetail2"),
              ]}
            />
          </Box>
        </> 
        )}

      {serviceType === 'digital' && (    
        <>
        <Typography variant="h5" sx={{ textAlign: "center", mt: 5, mb: 0.5 }}>
          {t("subscription.digitalPlan")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <SubscriptionCard
            title={t("subscription.digitalTitle")}
            price="6"
            serviceType="digital"
            planType="d1"
            isActive={isPlanActive("digital", "d1")}
            loading={loadingStates["d1"] || false}
            details={[
              t("subscription.nrProjects") + " 3",
              t("subscription.digitalDetail1"),
              t("subscription.digitalDetail2"),
            ]}
          />

          <SubscriptionCard
            title={t("subscription.digitalTitle")}
            price="16"
            serviceType="digital"
            planType="d2"
            isActive={isPlanActive("digital", "d2")}
            loading={loadingStates["d2"] || false}
            details={[
              t("subscription.nrProjects") + " 10",
              t("subscription.digitalDetail1"),
              t("subscription.digitalDetail2"),
            ]}
          />

          <SubscriptionCard
            title={t("subscription.digitalTitle")}
            price="32"
            serviceType="digital"
            planType="d3"
            isActive={isPlanActive("digital", "d3")}
            loading={loadingStates["d3"] || false}
            details={[
              t("subscription.nrProjects") + " 16",
              t("subscription.digitalDetail1"),
              t("subscription.digitalDetail2"),
            ]}
          />

          <SubscriptionCard
            title={t("subscription.digitalTitle")}
            price="64"
            serviceType="digital"
            planType="d4"
            isActive={isPlanActive("digital", "d4")}
            loading={loadingStates["d4"] || false}
            details={[
              t("subscription.nrProjects") + " 40",
              t("subscription.digitalDetail1"),
              t("subscription.digitalDetail2"),
            ]}
          />
        </Box>
        </> 
      )}
      </Box>
    </Container>
  );
};

export default SubscriptionOptions;
