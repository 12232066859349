import React from "react";
import Privacy1Img from '../../assets/Images/privacy1.png';
import Privacy2Img from '../../assets/Images/privacy2.png';
import Privacy3Img from '../../assets/Images/privacy3.png';
import Privacy4Img from '../../assets/Images/privacy4.png';
import Privacy5Img from '../../assets/Images/privacy5.png';
import Privacy6Img from '../../assets/Images/privacy6.png';
import MsgIcon from '../../assets/Icons/msgIcon.png';
import LocationIcon from '../../assets/Icons/locationIcon.png';
import CallIcon from '../../assets/Icons/callIcon.png';
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="privacy-container">
      <div className="privacy-controller-container">
        <p style={{marginBottom:"30px"}}>{t('privacyPolicy.title')}</p>
        <div className="privacy-content">
          <div className="pc-1">
            <div>
              <p>{t('privacyPolicy.introduction')}</p>
              <p>{t('privacyPolicy.introductionContent')}</p>
            </div>
            <div>
              <img src={Privacy1Img} alt="privacy-img-1" />
            </div>
          </div>
          <div className="pc-5">
            <div>
              <p>{t('privacyPolicy.informationWeCollect')}</p>
            </div>
            <div className="pc-5-content">
              <div>
                <p>{t('privacyPolicy.collectInformation')}</p>
                <p>{t('privacyPolicy.yourDataRightsContent.0')}</p>          
                <ul>
                <li>{t('privacyPolicy.personalInformationContent')}</li>
                <li>{t('privacyPolicy.usageDataContent')}</li>
                <li>{t('privacyPolicy.cookiesContent')}</li>
              </ul>
        
              </div>
              <div>
                <img src={Privacy2Img} alt="privacy-img-5" />
              </div>
            </div>
          </div>
          <div className="pc-5">
            <div>
              <p>{t('privacyPolicy.howWeUseYourInformation')}</p>
            </div>
            <div className="pc-5-content">
              <div>
                <p>{t('privacyPolicy.howWeUseYourInformationContent.0')}</p>      
                <ul>
                <li>{t('privacyPolicy.howWeUseYourInformationContent.1')}</li>
                <li>{t('privacyPolicy.howWeUseYourInformationContent.2')}</li>
                <li>{t('privacyPolicy.howWeUseYourInformationContent.3')}</li>
                <li>{t('privacyPolicy.howWeUseYourInformationContent.4')}</li>
              </ul>
        
              </div>
              <div>
                <img src={Privacy3Img} alt="privacy-img-5" />
              </div>
            </div>
          </div>
          <div className="privacy-contact">
            <div className="pc-4">
              <div>
                <p>{t('privacyPolicy.sharingYourInformation')}</p>
                <p>{t('privacyPolicy.sharingYourInformationContent')}</p>
              </div>
              <div>
                {/* <img src={MsgIcon} alt="email-icon" /> */}
                {/* <p className="email-display">{t('privacyPolicy.email')}</p> */}
                {/* <img src={LocationIcon} alt="location-icon" />
                <p>{t('privacyPolicy.address')}</p>
                <img src={CallIcon} alt="call-icon" />
                <p>{t('privacyPolicy.phone')}</p> */}
              </div>
            </div>
            <div>
              <img src={Privacy4Img} alt="privacy-img-4" />
            </div>
          </div>
          <div className="privacy-contact">
            <div className="pc-4">
              <div>
                <p>{t('privacyPolicy.dataSecurity')}</p>
                <p>{t('privacyPolicy.dataSecurityContent')}</p>
              </div>
              <div>

              </div>
            </div>
            <div>
              <img src={Privacy6Img} alt="privacy-img-4" />
            </div>
          </div>
          <div className="pc-5">
            <div>
              <p>{t('privacyPolicy.yourDataRights')}</p>
            </div>
            <div className="pc-5-content">
              <div>
                <p>{t('privacyPolicy.yourDataRightsTitle')}</p>
                <p>{t('privacyPolicy.yourDataRightsContent.0')}</p>
                <ul>
                  <li>{t('privacyPolicy.yourDataRightsContent.1')}</li>
                  <li>{t('privacyPolicy.yourDataRightsContent.2')}</li>
                  <li>{t('privacyPolicy.yourDataRightsContent.3')}</li>
                  <li>{t('privacyPolicy.yourDataRightsContent.4')}</li>
                </ul>
              </div>
              <div>
                <img src={Privacy5Img} alt="privacy-img-5" />
              </div>
            </div>
          </div>
          <div className="privacy-contact">
            <div className="pc-4">
              <div>
                <p>{t('privacyPolicy.dataRetention')}</p>
                <p>{t('privacyPolicy.dataRetentionContent')}</p>
              </div>
              <div>

              </div>
            </div>
            <div>
              {/* <img src={Privacy4Img} alt="privacy-img-4" /> */}
            </div>
          </div>
          <div className="privacy-contact">
            <div className="pc-4">
              <div>
                <p>{t('privacyPolicy.cookiesAndTracking')}</p>
                <p>{t('privacyPolicy.cookiesAndTrackingContent')}</p>
              </div>
              <div>

              </div>
            </div>
            <div>
              {/* <img src={Privacy4Img} alt="privacy-img-4" /> */}
            </div>
          </div>
          <div className="privacy-contact">
            <div className="pc-4">
              <div>
                <p>{t('privacyPolicy.thirdPartyLinks')}</p>
                <p>{t('privacyPolicy.thirdPartyLinksContent')}</p>
              </div>
              <div>

              </div>
            </div>
            <div>
              {/* <img src={Privacy4Img} alt="privacy-img-4" /> */}
            </div>
          </div>
          <div className="privacy-contact-end">
            <div className="pc-4">
              <div>
                <p>{t('privacyPolicy.changesToThisPolicy')}</p>
                <p>{t('privacyPolicy.changesToThisPolicyContent')}</p>
              </div>
              <div>

              </div>
            </div>
            <div>
              {/* <img src={Privacy4Img} alt="privacy-img-4" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
