import React, { useEffect, useState } from "react";
import RealEstateScan from "./RealEstateScan";
import RealEstateDigital from './RealEstateDigital';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Services() {
  const { type } = useParams(); // Get the current tab type from URL
  const navigate = useNavigate();
  const [value, setValue] = useState(type || 'scan'); // Set default to 'scan' if type is undefined
  const { t } = useTranslation();

  useEffect(() => {
    if (type !== value) {
      setValue(type); // Sync state with URL parameter
    }
  }, [type]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/services/${newValue}`); // Update URL on tab change
  };

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1', mt: 10, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
        overflowX:'hidden'
       }}>
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="Services Tab"
              sx={{
                '& .MuiTab-root': {
                  color: '#1a477f',
                  fontSize: '18px',
                  textTransform: 'none',
                  '&.Mui-selected': {
                    fontWeight: 600
                  }
                }
              }}
            >
              <Tab label={t('nav.scan')} value="scan" />
              <Tab label={t('nav.digital')} value="digital" />
            </TabList>
          </Box>
          <Box>
            <TabPanel value="scan">
              <RealEstateScan />
            </TabPanel>
            <TabPanel value="digital">
              <RealEstateDigital />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </>
  );
}
