import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { getForgeToken } from "../../API/apiService";
import {
  getAllWalls,
  getAllWindows,
  getAllFloors,
  getAllCeilings,
  getAllColumns,
  getAllConduitFittings,
  getAllConduits,
  getAllCasework,
  getAllDoors,
  getAllDuctFittings,
  getAllDucts,
  getAllFurniture,
  getAllGenericModels,
  getAllLightingFixtures,
  getAllMechanicalEquipment,
  getAllPipeFittings,
  getAllPipes,
  getAllPlumbingFixtures,
  getAllRailings,
  getAllSpecialityEquipment,
  getAllStairs,
  getAllStructuralColumns,
  getAllTopography,
  getAllSites,
  getAllStructuralFraming,
  isolateElements,
  getAllArchitectureCategory,
  getAllFurnitureCategory,
  getAllMEPFCategory,
  getAllPlantings,
  removeElementsFromIsolation
} from "../../Helpers/forgeViewerUtils";

const ForgeViewer = forwardRef(({ urn }, ref) => {
  const [viewer, setViewer] = useState(null);
  const viewerRef = useRef(null);

  useEffect(() => {
    if (!urn) return;

    const initializeViewer = async () => {
      if (!viewerRef.current) return;
      const options = {
        env: "AutodeskProduction",
        getAccessToken: getForgeToken,
      };

      Autodesk.Viewing.Initializer(options, () => {
        const newViewer = new Autodesk.Viewing.GuiViewer3D(viewerRef.current);
        newViewer.start();
        setViewer(newViewer);

        const documentId = "urn:" + urn;
        Autodesk.Viewing.Document.load(
          documentId,
          (doc) => {
            onDocumentLoadSuccess(doc, newViewer);
          },
          onDocumentLoadFailure
        );
      });
    };

    initializeViewer();

    return () => {
      if (viewer) {
        viewer.finish();
      }
    };
  }, [urn]);

  const onDocumentLoadSuccess = (doc, viewerInstance) => {
    const viewables = doc.getRoot().getDefaultGeometry();
    viewerInstance.loadDocumentNode(doc, viewables).then(() => {
      // Attach the event listener after loading the document
      viewerInstance.addEventListener(
        Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        () => {
          viewerInstance.setEnvMapBackground(false); // Turn off background image
          viewerInstance.setBackgroundColor(255, 255, 255, 255, 255, 255); // Change background color (adjust values as needed)
        },
        { once: true }
      ); // Run this handler once
    });
  };

  const onDocumentLoadFailure = (viewerErrorCode) => {
    //console.error("onDocumentLoadFailure() - errorCode:" + viewerErrorCode);
  };


  useImperativeHandle(ref, () => ({
    getWalls: () => getAllWalls(viewer),
    getWindows: () => getAllWindows(viewer),
    getFloors: () => getAllFloors(viewer),
    getCasework: () => getAllCasework(viewer),
    getCeilings: () => getAllCeilings(viewer),
    getColumns: () => getAllColumns(viewer),
    getConduitFittings: () => getAllConduitFittings(viewer),
    getConduits: () => getAllConduits(viewer),
    getDoors: () => getAllDoors(viewer),
    getDuctFittings: () => getAllDuctFittings(viewer),
    getDucts: () => getAllDucts(viewer),
    getElementsByCategory: () => getAllElementsByCategory(viewer),
    getFurniture: () => getAllFurniture(viewer),
    getGenericModels: () => getAllGenericModels(viewer),
    getLightingFixtures: () => getAllLightingFixtures(viewer),
    getMechanicalEquipment: () => getAllMechanicalEquipment(viewer),
    getPipeFittings: () => getAllPipeFittings(viewer),
    getPipes: () => getAllPipes(viewer),
    getPlumbingFixtures: () => getAllPlumbingFixtures(viewer),
    getRailings: () => getAllRailings(viewer),
    getSpecialityEquipment: () => getAllSpecialityEquipment(viewer),
    getStairs: () => getAllStairs(viewer),
    getStructuralColumns: () => getAllStructuralColumns(viewer),
    getPlantings: () => getAllPlantings(viewer),
    getTopography: () => getAllTopography(viewer),
    getSite: () => getAllSites(viewer),
    getStructuralFraming: () => getAllStructuralFraming(viewer),
    getArchitectureCategory: (action) => getAllArchitectureCategory(viewer, action),
    getFurnitureCategory: (action) => getAllFurnitureCategory(viewer, action),
    getMEPFCategory: (action) => getAllMEPFCategory(viewer, action),
    isolateGroup: (dbIds) => isolateElements(viewer, dbIds),
    removeFromIsolation: () => removeElementsFromIsolation(viewer, dbIds),
   
  }));

  // Define other element retrieval methods similarly using getAllElementsByCategory

  return (
    <div style={{marginTop:'10px'}}>
      <div
        className="auto-desk-container"
        ref={viewerRef}
        style={{display:'flex',justifyItems:'center', width: "50%", height: "53vh", padding:'10px' , zIndex:1}}
      ></div>
    </div>
  );
});

export default ForgeViewer;
