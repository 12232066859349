export const pricingDigital = {
    "For Clients": {
        "Price": {
            "0-250 SF": {
              "Interior": {
                "ARCH": { "Standart": 107.75, "Industrial": 424.50 },
                "ARCH & MEP": { "Standart": 152.33, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 227.83, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 272.38, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 96.98, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 128.18, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 115.00, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 146.18, "Industrial": 0 },
                "SITE": { "Standart": 16.18, "Industrial": 0 }
              }
            },
            "251-500 SF": {
              "Interior": {
                "ARCH": { "Standart": 134.70, "Industrial": 586.50 },
                "ARCH & MEP": { "Standart": 182.08, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 272.33, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 319.70, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 121.23, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 33.18, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 141.88, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 154.40, "Industrial": 0 },
                "SITE": { "Standart": 20.20, "Industrial": 0 }
              }
            },
            "501-750 SF": {
              "Interior": {
                "ARCH": { "Standart": 161.65, "Industrial": 748.50 },
                "ARCH & MEP": { "Standart": 211.83, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 316.83, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 367.03, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 145.48, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 180.63, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 168.75, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 203.90, "Industrial": 0 },
                "SITE": { "Standart": 24.25, "Industrial": 0 }
              }
            },
            "751-1000 SF": {
              "Interior": {
                "ARCH": { "Standart": 188.58, "Industrial": 910.50 },
                "ARCH & MEP": { "Standart": 241.60, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 361.35, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 414.35, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 169.73, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 206.83, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 195.65, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 232.75, "Industrial": 0 },
                "SITE": { "Standart": 28.28, "Industrial": 0 }
              }
            },
            "1001-2000 SF": {
              "Interior": {
                "ARCH": { "Standart": 350.20, "Industrial": 1527.93 },
                "ARCH & MEP": { "Standart": 436.28, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 548.50, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 634.58, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 315.20, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 375.45, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 344.93, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 405.18, "Industrial": 0 },
                "SITE": { "Standart": 52.53, "Industrial": 0 }
              }
            },
            "2001-3000 SF": {
              "Interior": {
                "ARCH": { "Standart": 411.88, "Industrial": 1647.45 },
                "ARCH & MEP": { "Standart": 516.43, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 639.25, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 743.80, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 370.68, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 443.88, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 404.80, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 477.98, "Industrial": 0 },
                "SITE": { "Standart": 61.78, "Industrial": 0 }
              }
            },
            "3001-4000 SF": {
              "Interior": {
                "ARCH": { "Standart": 473.53, "Industrial": 1766.95 },
                "ARCH & MEP": { "Standart": 596.58, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 730.00, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 853.03, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 426.18, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 512.30, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 464.65, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 550.78, "Industrial": 0 },
                "SITE": { "Standart": 71.03, "Industrial": 0 }
              }
            },
            "4001-5000 SF": {
              "Interior": {
                "ARCH": { "Standart": 535.20, "Industrial": 1886.45 },
                "ARCH & MEP": { "Standart": 676.70, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 820.75, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 962.25, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 481.68, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 580.75, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 524.50, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 623.58, "Industrial": 0 },
                "SITE": { "Standart": 80.28, "Industrial": 0 }
              }
            },
            "5001-6000 SF": {
              "Interior": {
                "ARCH": { "Standart": 596.85, "Industrial": 2005.98 },
                "ARCH & MEP": { "Standart": 756.85, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 911.50, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 1071.50, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 537.18, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 649.18, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 584.38, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 696.38, "Industrial": 0 },
                "SITE": { "Standart": 89.53, "Industrial": 0 }
              }
            },
            "6001-7000 SF": {
              "Interior": {
                "ARCH": { "Standart": 658.53, "Industrial": 2125.48 },
                "ARCH & MEP": { "Standart": 837.00, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 1002.25, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 1180.73, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 592.68, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 717.60, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 644.23, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 769.15, "Industrial": 0 },
                "SITE": { "Standart": 98.78, "Industrial": 0 }
              }
            },
            "7001-8000 SF": {
              "Interior": {
                "ARCH": { "Standart": 720.18, "Industrial": 2245.00 },
                "ARCH & MEP": { "Standart": 917.15, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 1093.00, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 1289.95, "Industrial": 0 }
              },
              "Exterior": {
                "ARCH": { "Standart": 648.18, "Industrial": 0 },
                "ARCH & MEP": { "Standart": 786.03, "Industrial": 0 },
                "ARCH & FURNITURE": { "Standart": 704.08, "Industrial": 0 },
                "ARCH & FURNITURE & MEP": { "Standart": 841.95, "Industrial": 0 },
                "SITE": { "Standart": 108.03, "Industrial": 0 }
              }
            },           
                "8001-9000 SF": {
                  "Interior": {
                    "ARCH": { "Standart": 781.85, "Industrial": 2364.50 },
                    "ARCH & MEP": { "Standart": 997.28, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 1183.75, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 1399.18, "Industrial": 0 }
                  },
                  "Exterior": {
                    "ARCH": { "Standart": 703.65, "Industrial": 0 },
                    "ARCH & MEP": { "Standart": 854.48, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 763.95, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 914.75, "Industrial": 0 },
                    "SITE": { "Standart": 117.28, "Industrial": 0 }
                  }
                },
                "9001-10000 SF": {
                  "Interior": {
                    "ARCH": { "Standart": 843.50, "Industrial": 2484.00 },
                    "ARCH & MEP": { "Standart": 1077.43, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 1274.50, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 1508.43, "Industrial": 0 }
                  },
                  "Exterior": {
                    "ARCH": { "Standart": 759.15, "Industrial": 0 },
                    "ARCH & MEP": { "Standart": 922.90, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 823.80, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 987.55, "Industrial": 0 },
                    "SITE": { "Standart": 126.53, "Industrial": 0 }
                  }
                },
                "10001-15000 SF": {
                  "Interior": {
                    "ARCH": { "Standart": 1151.83, "Industrial": 3081.55 },
                    "ARCH & MEP": { "Standart": 1478.13, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 1728.25, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 2054.58, "Industrial": 0 }
                  },
                  "Exterior": {
                    "ARCH": { "Standart": 1036.63, "Industrial": 0 },
                    "ARCH & MEP": { "Standart": 1265.05, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 1123.10, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 1351.53, "Industrial": 0 },
                    "SITE": { "Standart": 172.78, "Industrial": 0 }
                  }
                },
                "15001-20000 SF": {
                  "Interior": {
                    "ARCH": { "Standart": 1460.13, "Industrial": 3679.10 },
                    "ARCH & MEP": { "Standart": 1878.85, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 2182.00, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 2600.75, "Industrial": 0 }
                  },
                  "Exterior": {
                    "ARCH": { "Standart": 1314.10, "Industrial": 0 },
                    "ARCH & MEP": { "Standart": 1607.23, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 1422.40, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 1715.50, "Industrial": 0 },
                    "SITE": { "Standart": 219.03, "Industrial": 0 }
                  }
                },
                "20001-25000 SF": {
                  "Interior": {
                    "ARCH": { "Standart": 1768.43, "Industrial": 4276.65 },
                    "ARCH & MEP": { "Standart": 2279.58, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 2635.78, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 3146.90, "Industrial": 0 }
                  },
                  "Exterior": {
                    "ARCH": { "Standart": 1591.58, "Industrial": 0 },
                    "ARCH & MEP": { "Standart": 1949.38, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 1721.70, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 2079.48, "Industrial": 0 },
                    "SITE": { "Standart": 265.28, "Industrial": 0 }
                  }
                },
                "25001-30000 SF": {
                  "Interior": {
                    "ARCH": { "Standart": 2076.75, "Industrial": 4874.20 },
                    "ARCH & MEP": { "Standart": 2680.28, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 3089.53, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 3693.05, "Industrial": 0 }
                  },
                  "Exterior": {
                    "ARCH": { "Standart": 1869.08, "Industrial": 0 },
                    "ARCH & MEP": { "Standart": 2291.55, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 2020.98, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 2443.45, "Industrial": 0 },
                    "SITE": { "Standart": 311.50, "Industrial": 0 }
                  }
                },
                "30001-35000 SF": {
                  "Interior": {
                    "ARCH": { "Standart": 2385.05, "Industrial": 5471.75 },
                    "ARCH & MEP": { "Standart": 3081.00, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 3543.28, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 4239.23, "Industrial": 0 }
                  },
                  "Exterior": {
                    "ARCH": { "Standart": 2146.55, "Industrial": 0 },
                    "ARCH & MEP": { "Standart": 2633.70, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 2320.28, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 2807.43, "Industrial": 0 },
                    "SITE": { "Standart": 357.75, "Industrial": 0 }
                  }
                },
                "35001-40000 SF": {
                  "Interior": {
                    "ARCH": { "Standart": 2693.35, "Industrial": 6069.30 },
                    "ARCH & MEP": { "Standart": 3481.70, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 3997.03, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 4785.38, "Industrial": 0 }
                  },
                  "Exterior": {
                    "ARCH": { "Standart": 2424.03, "Industrial": 0 },
                    "ARCH & MEP": { "Standart": 2975.88, "Industrial": 0 },
                    "ARCH & FURNITURE": { "Standart": 2619.58, "Industrial": 0 },
                    "ARCH & FURNITURE & MEP": { "Standart": 3171.43, "Industrial": 0 },
                    "SITE": { "Standart": 404.00, "Industrial": 0 }
                  }
                },
             
                    "40001-45000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 3001.68, "Industrial": 6666.83 },
                        "ARCH & MEP": { "Standart": 3882.43, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 4450.80, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 5331.55, "Industrial": 0 }
                      },

                      "Exterior": {
                        "ARCH": { "Standart": 2701.50 },
                        "ARCH & MEP": { "Standart": 3318.03 },
                        "ARCH & FURNITURE": { "Standart": 2918.88 },
                        "ARCH & FURNITURE & MEP": { "Standart": 3535.40 },
                        "SITE": { "Standart": 450.25 }
                      }
                    },
                    "45001-50000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 3309.98, "Industrial": 7264.38 },
                        "ARCH & MEP": { "Standart": 4283.13, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 4904.55, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 5877.70, "Industrial": 0 }
                      },

                      "Exterior": {
                        "ARCH": { "Standart": 2978.98 },
                        "ARCH & MEP": { "Standart": 3660.18 },
                        "ARCH & FURNITURE": { "Standart": 3218.15 },
                        "ARCH & FURNITURE & MEP": { "Standart": 3899.38 },
                        "SITE": { "Standart": 496.50 }
                      }
                    },
                    "50001-62500 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 3724.40, "Industrial": 9506.25 },
                        "ARCH & MEP": { "Standart": 5169.20, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 5942.05, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 7386.85, "Industrial": 0 }
                      },

                      "Exterior": {
                        "ARCH": { "Standart": 3351.98 },
                        "ARCH & MEP": { "Standart": 4363.33 },
                        "ARCH & FURNITURE": { "Standart": 3684.63 },
                        "ARCH & FURNITURE & MEP": { "Standart": 4695.98 },
                        "SITE": { "Standart": 558.65 }
                      }
                      },
                      
                 
                    "62501-75000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 4121.63, "Industrial": 10481.25},
                        "ARCH & MEP": { "Standart": 5661.55, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 6987.28, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 8527.18, "Industrial": 0 }
                        },
                        "Exterior": {
                              "ARCH": { "Standart": 3709.48 },
                              "ARCH & MEP": { "Standart": 4787.40 },
                              "ARCH & FURNITURE": { "Standart": 4139.33 },
                              "ARCH & FURNITURE & MEP": { "Standart": 5217.25 },
                              "SITE": { "Standart": 618.25 }
                            }
                      },
                      
    
                    "75001-87500 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 4518.85, "Industrial": 11456.25 },
                        "ARCH & MEP": { "Standart": 6153.88, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 8032.50, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 9667.53, "Industrial": 0 }
                      },
  
                      "Exterior": {
                        "ARCH": { "Standart": 4066.98 },
                        "ARCH & MEP": { "Standart": 5211.48 },
                        "ARCH & FURNITURE": { "Standart": 4594.03 },
                        "ARCH & FURNITURE & MEP": { "Standart": 5738.53 },
                        "SITE": { "Standart": 677.83 }
                      }
                    },
                    "87501-100000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 4916.08, "Industrial":  12431.25 },
                        "ARCH & MEP": { "Standart": 6646.20, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 9077.73, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 10807.85, "Industrial": 0 }
                      },

                      "Exterior": {
                        "ARCH": { "Standart": 4424.48 },
                        "ARCH & MEP": { "Standart": 5635.55 },
                        "ARCH & FURNITURE": { "Standart": 5048.73 },
                        "ARCH & FURNITURE & MEP": { "Standart": 6259.80 },
                        "SITE": { "Standart": 737.40 }
                      }
                    },
                    "100001-125000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 5963.98, "Industrial": 16839.48 },
                        "ARCH & MEP": { "Standart": 8239.33, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 11262.18, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 13537.53, "Industrial": 0 }
                      },

                      "Exterior": {
                        "ARCH": { "Standart": 5367.58 },
                        "ARCH & MEP": { "Standart": 6960.33 },
                        "ARCH & FURNITURE": { "Standart": 6162.30 },
                        "ARCH & FURNITURE & MEP": { "Standart": 7755.05 },
                        "SITE": { "Standart": 894.60 }
                      }
                    },
                    "125001-150000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 6738.58, "Industrial": 19211.18 },
                        "ARCH & MEP": { "Standart": 9318.43, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 12737.23, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 15317.10, "Industrial": 0 }
                      },

                      "Exterior": {
                        "ARCH": { "Standart": 6064.73 },
                        "ARCH & MEP": { "Standart": 7870.63 },
                        "ARCH & FURNITURE": { "Standart": 6964.53 },
                        "ARCH & FURNITURE & MEP": { "Standart": 8770.43 },
                        "SITE": { "Standart": 1010.78 }
                      }
                    },
                    "150001-175000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 7513.18, "Industrial": 21582.88 },
                        "ARCH & MEP": { "Standart": 10397.55, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 14212.30, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 17096.68, "Industrial": 0 }
                      },

                      "Exterior": {
                        "ARCH": { "Standart": 6761.88 },
                        "ARCH & MEP": { "Standart": 8780.93 },
                        "ARCH & FURNITURE": { "Standart": 7766.73 },
                        "ARCH & FURNITURE & MEP": { "Standart": 9785.80 },
                        "SITE": { "Standart": 1126.98 }
                      }
                    },
                    "175001-200000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 8287.80, "Industrial": 23954.55 },
                        "ARCH & MEP": { "Standart": 11476.65, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 15687.38, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 18876.23, "Industrial": 0 }
                      },
      
                      "Exterior": {
                        "ARCH": { "Standart": 7459.03 },
                        "ARCH & MEP": { "Standart": 9691.23 },
                        "ARCH & FURNITURE": { "Standart": 8568.95 },
                        "ARCH & FURNITURE & MEP": { "Standart": 10801.15 },
                        "SITE": { "Standart": 1243.18 }
                      }
                    },
                    "200001-225000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 9062.40, "Industrial": 26326.25 },
                        "ARCH & MEP": { "Standart": 12555.78, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 17162.43, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 20655.80, "Industrial": 0 }
                      },
   
                      "Exterior": {
                        "ARCH": { "Standart": 8156.15 },
                        "ARCH & MEP": { "Standart": 10601.53 },
                        "ARCH & FURNITURE": { "Standart": 9371.18 },
                        "ARCH & FURNITURE & MEP": { "Standart": 11816.53 },
                        "SITE": { "Standart": 1359.35 }
                      }
                    },
                    "225001-250000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 9837.00, "Industrial":  28697.95 },
                        "ARCH & MEP": { "Standart": 13634.88, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 18637.50, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 22435.38, "Industrial": 0 }
                      },
 
                      "Exterior": {
                        "ARCH": { "Standart": 8853.30 },
                        "ARCH & MEP": { "Standart": 11511.83 },
                        "ARCH & FURNITURE": { "Standart": 10173.38 },
                        "ARCH & FURNITURE & MEP": { "Standart": 12831.90 },
                        "SITE": { "Standart": 1475.55 }
                      }
                    },
                    "250001-275000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 10611.63, "Industrial": 31069.63 },
                        "ARCH & MEP": { "Standart": 14714.00, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 20112.58, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 24214.95, "Industrial": 0 }
                      },
    
                      "Exterior": {
                        "ARCH": { "Standart": 9550.45 },
                        "ARCH & MEP": { "Standart": 12422.13 },
                        "ARCH & FURNITURE": { "Standart": 10975.60 },
                        "ARCH & FURNITURE & MEP": { "Standart": 13847.25 },
                        "SITE": { "Standart": 1591.75 }
                      }
                    },
                    "275001-300000 SF": {
                      "Interior": {
                        "ARCH": { "Standart": 11386.23, "Industrial": 33441.33 },
                        "ARCH & MEP": { "Standart": 15793.10, "Industrial": 0 },
                        "ARCH & FURNITURE": { "Standart": 21587.65, "Industrial": 0 },
                        "ARCH & FURNITURE & MEP": { "Standart": 25994.50, "Industrial": 0 }
                      },
                 
                      "Exterior": {
                        "ARCH": { "Standart": 10247.60 },
                        "ARCH & MEP": { "Standart": 13332.43 },
                        "ARCH & FURNITURE": { "Standart": 11777.83 },
                        "ARCH & FURNITURE & MEP": { "Standart": 14862.63 },
                        "SITE": { "Standart": 1707.93 }
                      }
                    }
                  }    
    }    
};

// Accessing data example
//console.log(pricingData["For Clients"]["Price"]["0-250 SF"]["Interior"]["ARCH"]["€"]); // Outputs: 107.75
