import React from "react";
import { useTranslation } from 'react-i18next';
import Terms1Img from '../../assets/Images/terms-1.png'
import Terms2Img from '../../assets/Images/terms-2.png'
import MsgIcon from '../../assets/Icons/msgIcon.png'
import Privacy4Img from '../../assets/Images/privacy4.png'

export default function TermsAndCondition() {
    const { t } = useTranslation();

    return (
        <div className="terms-container">
            <p className="title-custom-2">{t('terms.termsAndConditionsTitle')}</p> 
            <div className="terms-intro">             
                <div>
                    <p className="title-custom">{t('terms.introductionTitle')}</p>
                    <p className="simple-text">{t('terms.introductionContent')}</p>
                </div>
                <div>
                    <img src={Terms1Img} alt="terms-img-1" />
                </div>
            </div>
            <div className="terms-acceptance">
                <div>
                    <ul>
                        <li className="unorder-list2">{t('terms.servicesTitle')}</li>
                    </ul>
                    <p className="simple-text">{t('terms.servicesContent')}</p>
                    {/* <ul>
                        <li className="unorder-list2">{t('terms.userAccountsTitle')}</li>
                    </ul>
                    <p className="simple-text" style={{textAlign:'start'}}>{t('terms.userAccountsText')}</p>
                    <ul className="simple-text">
                        <li>{t('terms.userAccountsListItem1')}</li>
                        <li>{t('terms.userAccountsListItem2')}</li>
                        <li>{t('terms.userAccountsListItem3')}</li>
                        <li>{t('terms.userAccountsListItem4')}</li>
                    </ul> */}
                </div>
                <div className="terms-use-platform">
                    <ul>
                        <li className="unorder-list2">{t('terms.accountRegistrationTitle')}</li>
                    </ul>
                    <p className="simple-text">{t('terms.accountRegistrationContent')}</p>
                    {/* <ul className="simple-text">
                        <li>{t('terms.useOfPlatformListItem1')}</li>
                        <li>{t('terms.useOfPlatformListItem2')}</li>
                        <li>{t('terms.useOfPlatformListItem3')}</li>
                    </ul> */}
                </div>
            </div>
            <div className="property-rights">
                <div>
                    <ul>
                        <li className="unorder-list2">{t('terms.paymentsAndPricingTitle')}</li>
                    </ul>
                    <p className="simple-text">{t('terms.paymentsAndPricingContent')}</p>
                </div>
                <div>
                    <img src={Terms2Img} alt="terms-img-2" />
                </div>
            </div>
            <div className="terms-info">
                <div>
                    <ul>
                        <li className="unorder-list2">{t('terms.refundPolicyTitle')}</li>
                    </ul>
                    <p className='simple-text'>{t('terms.refundPolicyContent')}</p>
                </div>
                <div>
                    <ul>
                        <li className="unorder-list2">{t('terms.intellectualPropertyTitle')}</li> 
                    </ul>
                    <p className='simple-text'>{t('terms.intellectualPropertyContent')}</p>
                </div>
                <div>
                    <ul>
                        <li className="unorder-list2">{t('terms.userConductTitle')}</li>
                    </ul>
                    <p className='simple-text'>{t('terms.userConductContent')}</p>
                </div>
                <div>
                    <ul>
                        <li className="unorder-list2">{t('terms.limitationOfLiabilityTitle')}</li>
                    </ul>
                    <p className='simple-text'>{t('terms.limitationOfLiabilityContent')}</p>
                </div>
                <div>
                    <ul>
                        <li className="unorder-list2">{t('terms.modificationsToTermsTitle')}</li>
                    </ul>
                    <p className='simple-text'>{t('terms.modificationsToTermsContent')}</p>
                </div>
                <div>
                    <ul>
                        <li className="unorder-list2">{t('terms.governingLawTitle')}</li>
                    </ul>
                    <p className='simple-text'>{t('terms.governingLawContent')}</p>
                </div>
            </div>
            {/* <div className="privacy-contact contact-terms">
                <div className="pc-4">
                    <div>
                        <p>{t('terms.contactUs')}</p>
                        <p>{t('terms.contactText')}</p>
                    </div>
                    <div>
                        <img src={MsgIcon} alt="email-icon" />
                        <p className="email-display">{t('terms.email')}</p>
                    </div>
                </div>
                <div>
                    <img src={Privacy4Img} alt="privacy-img-4" />
                </div>
            </div> */}
            <p className="thanksTxt">{t('terms.thankYou')}</p>
        </div>
    );
}
