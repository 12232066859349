// ShareDialog.jsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { FaFacebook, FaInstagram, FaEnvelope, FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ShareDialog = ({ open, onClose, url, title }) => {
  const { t } = useTranslation();
  const encodedUrl = encodeURIComponent(url);
  const encodedTitle = encodeURIComponent(title);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('share.title')}</DialogTitle>
      <DialogContent>
        <div className="share-buttons">
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`} target="_blank" rel="noopener noreferrer">
            <FaFacebook size={32} />
          </a>
          <a href={`mailto:?subject=${encodedTitle}&body=${encodedUrl}`} target="_blank" rel="noopener noreferrer">
            <FaEnvelope color='#d44638' size={32} />
          </a>
          <a href={`https://www.instagram.com`} target="_blank" rel="noopener noreferrer">
            <FaInstagram color='#C13584' size={32} />
          </a>
          <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}`} target="_blank" rel="noopener noreferrer">
            <FaLinkedin color=' #0a66c2' size={32} />
          </a>
          <a href={`https://api.whatsapp.com/send?text=${encodedTitle} ${encodedUrl}`} target="_blank" rel="noopener noreferrer">
            <FaWhatsapp color='green' size={32} />
          </a>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('share.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
