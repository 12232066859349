import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DigitalOrderImg from "../../assets/Images/digitalOrder.png";
import {
  getDigitalProjects,
  getDigitalProjectsByUserId,
  paymentCheckout
} from "../../API/apiService";
import { Tooltip,
  Modal, Box, Typography, Button,CircularProgress
   } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentUser, cancelProject } from "../../API/apiService";
import NoOrders from "./NoOrders";
import toast from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px", // Add this line for border radius
  boxShadow: 24,
  p: 4,
};

export default function OrderDigital() {
  const { t } = useTranslation(); // Translation hook

  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderCancel, setOrderCancel] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const handleClosePayProjectModal = () => setOpenPayProjectModal(false);
  const [openPayProjectModal, setOpenPayProjectModal] = useState(false)
  const [paymentInProcess, setPaymentInProcess] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    if (getCurrentUser() === null) {
      navigate("/login");
      return;
    }

    const projectsDigital = async () => {
      try {
        setIsLoading(true);
        const result = await getDigitalProjectsByUserId(
          getCurrentUser()["id"]
        );
        if (result.success === true) {
       
          setOrders(result);
        }
      } catch (error) {
        setIsLoading(false);
        navigate("/server-error");
      } finally {
        setIsLoading(false);
      }
    };

    projectsDigital();
  }, [orderCancel]);
     

  const displayUnit = (unit) => {
    if (unit === "imperial") {
      return t("orderDigital.unitImperial");
    }
    return t("orderDigital.unitMetric");
  };

  const cancelProjectAsync = async (order) => {
    try {
      const payload = {
        projectId: order.projectId,
        serviceType: "Digital",
      };
      const response = await cancelProject(payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOrderCancel(!orderCancel);
    }
  };

  const handleCancelproject = async (event, order) => {
    event.stopPropagation();
    event.preventDefault();
    if (order.status === "Draft") {
      const response = cancelProjectAsync(order);
      toast.promise(
        response,
        {
          loading: t("orderDigital.loading"),
          success: <b>{t("orderDigital.success")}</b>,
          error: <b>{t("orderDigital.error")}</b>,
        },
        {
          success: { duration: 6000 },
        }
      );
    } else {
      toast.error(t("orderDigital.onlyDraftCancellation"));
    }
  };

  const handleOpenModalProject = (event, order) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenPayProjectModal(true)
    setSelectedProject(order)
  }

  const handlePayProject = async () => {
    try {
      setPaymentInProcess(true)
      const paymentPath = await paymentCheckout({
        projectId: selectedProject.projectId,
        totalPrice: selectedProject?.payment?.totalPrice,
        serviceType: "digital",
      });
   
      window.location.href = paymentPath.sessionUrl;
    } catch (error) {
      
    }finally{
      setPaymentInProcess(false)
    }
  
  }

  return orders?.data?.length === 0 ? (
    <div className="no-order">
    <NoOrders redirectTo="/services/digital" />
  </div>
  ) : (
    <div className="order-container">
      {isLoading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        orders?.data?.map((order) => (
          <Link
            className="link"
            key={order.projectId}
            to={`/orders/digital/${order.projectId}`}
          >
            <div key={order.projectId} className="order-card-container">
              <div className="order-card-info">
                <img
                  className="img-project"
                  src={order?.photoUrl || DigitalOrderImg}
                  alt="projectPhoto"
                />

                <p style={{alignSelf:'start'}} >{order.projectName}</p>
                <div className="cancel-order">
                  <div style={ order.status === "Draft" ? { width:'80%'} : { width:'100%'}}>
                    <p className="order-address">{order.propertyInfoDigital.address}</p>
                  </div>   
                  {order.status === "Draft" && (
                    <div>
                      {/* <p
                        onClick={(event) => handleCancelproject(event, order)}
                        className="refound-btn"
                      >
                        {t("orderDigital.cancelButton")}
                      </p>
                      <div className="tooltip">
                        {t("orderDigital.cancelTooltip")}
                      </div> */} 
                        <p
                          onClick={(event) => handleOpenModalProject(event, order)}
                          className="pay-btn"
                        >
                          {t("orderScan.payButton")}
                        </p>
                    </div>
                  )}
                </div>
              </div>

              <div className="card-param-cont">
                <p className="order-param param-label">
                  {t("orderDigital.propertyTypeLabel")}
                </p>
                <p className="order-param param-label">
                  {t("orderDigital.spaceSiteSizeLabel")}
                </p>
                <p className="order-param param-label">
                  {t("orderDigital.statusLabel")}
                </p>
                <p className="order-param param-info param-info1">
                  {order.propertyInfoDigital?.propertyType[0]?.type}
                </p>
                <p className="order-param param-info param-info2">
                  {order.propertyInfoDigital.propertySize}/
                  {order.propertyInfoDigital.landSize}
                  {displayUnit(order.propertyInfoDigital.unit)}
                </p>

                <p
                  className={`order-param param-info param-info3 status-${order.status}`}
                >
                {order.status === "InProgress" 
                  ? t('status.progress')
                  : order.status === "Draft" 
                  ? t('status.draft')
                  : order.status === "Canceled" 
                  ? t('status.canceled')
                  : order.status === "Completed" 
                  ? t('status.completed')
                  : null
                }
                </p>
              </div>
            </div>
          </Link>
        ))
      )}
        <Modal
        open={openPayProjectModal}
        onClose={handleClosePayProjectModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
          {t('common.continuePayment')}
          </Typography>
          <Typography 
            sx={{
              color:'grey'
            }}
          >
            {t('common.projectName')} {selectedProject.projectName}
            <br />
            {t('common.priceToPay')} {selectedProject?.payment?.totalPrice}
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePayProject}
              sx={{ mr: 1 }}
              disabled={paymentInProcess}
              startIcon={paymentInProcess ? <CircularProgress size={24} /> : null} 
            >
               {paymentInProcess ? t("priceScan.processing") : t("digitalService.yes")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClosePayProjectModal}
            >
              {t('digitalService.no')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
