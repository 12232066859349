import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NoOrders = ({ redirectTo }) => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60vh',
        textAlign: 'center',
        color: '#1a477f',
        padding: '2rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '3rem',
          bgcolor: '#fff',
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <ShoppingBasketOutlinedIcon sx={{ fontSize: 100, mb: 2, color: '#1a477f' }} />
        <Typography variant="h4" sx={{ mb: 1, fontWeight: 'bold' }}>
          {t('noOrders.title')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {t('noOrders.message')}
        </Typography>
        <Button
          component={Link}
          to={redirectTo}
          variant="contained"
          color="primary"
          sx={{
            bgcolor: '#1a477f',
            '&:hover': {
              bgcolor: '#163b5f',
            },
          }}
        >
          {t('noOrders.buttonText')}
        </Button>
      </Box>
    </Container>
  );
};

export default NoOrders;
