import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from '../../assets/Images/Logo.png';
import KeyReset from '../../assets/Icons/keyReset.png';
import { Button } from "@mui/material";
import { resetPassword } from '../../API/apiService';
import toast from "react-hot-toast";
import PasswordField from "../Orders/Auth/PasswordField";
import { useLocation } from "react-router-dom";
import { validatePassword } from '../../Validators/validator';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function ConfirmNewPassword() {
    const { t } = useTranslation();
    const query = useQuery();
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (event, parent, child) => {
        const value = event.target.value;
        setNewPassword(value);
    };

    useEffect(() => {
        setEmail(query.get('email') || '');
        setToken(query.get('token') || '');
    }, [query]);

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        try {
            const response = await resetPassword({ email, token, newPassword });
            if(response.success === true) {
                navigate('/password-confirmed');
                return;
            } else if(response.statusCode === 500) {
                navigate('/server-error');
            } else if(response.errorMessage === "Email does not exist.") {
                toast.error(response.errorMessage);
            }
        } catch (error) {
            if(error.response) {
                toast.error('Failed to request password reset. Please try again later.');
            } else if (error.request) {
                toast.error('Network error. Please check your internet connection and try again.');
            } else {
                toast.error('Something went wrong. Please try again later.');
                setNewPassword('');
            }
        }
    };

    return (
        <div className="reset-pass-container">
            <div className="reset-pass-content">
                <div>
                    <img src={Logo} alt="logo-img" />
                </div>
                <div className="reset-pass-form">
                    <img src={KeyReset} alt="key-icon" />
                    <h3>{t('confirmNewPassword.enterNewPassword')}</h3>
                    <p className="reset-info">
                        {t('confirmNewPassword.passwordCriteria')}
                    </p>
                    <PasswordField
                        label={t('confirmNewPassword.enterNewPassword')}
                        fieldName="password"
                        validator={validatePassword}
                        onChange={handleInputChange}
                    />
                    <Button
                        onClick={handlePasswordReset}
                        sx={{
                            padding: '12px',
                            fontSize: '12px',
                            fontWeight: 400,
                            borderRadius: '10px',
                            marginTop: '-15px',
                            border: 'none',
                            backgroundColor: '#1a477f',
                            color: 'white',
                            cursor: 'pointer',
                            textTransform: 'none',
                            width: { xs: '300px', md: '400px' },
                            '&:hover': {
                                backgroundColor: '#163D6C', 
                            }
                        }}
                    >
                        {t('confirmNewPassword.confirmButton')}
                    </Button>
                    <Link className="link" to='/login'>
                        <p className="back-to-login">{t('confirmNewPassword.backToLogin')}</p>
                    </Link>
                    <div className="terms-cont">
                        <p className="p-terms">
                            <Link className="link" to='/terms-Condition'>
                                <span className="term-policy-link">{t('confirmNewPassword.termsOfUse')}</span>
                            </Link>
                            <span> &nbsp; | &nbsp; </span>
                            <Link className="link" to='/privacy-policy'>
                                <span className="term-policy-link">{t('confirmNewPassword.privacyPolicy')}</span>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
