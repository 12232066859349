import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeImg from '../../assets/Images/HomeImg.png';
import ScanBanner from '../../assets/Images/scanBanner.png';
import DigitalBanner from '../../assets/Images/digitalBanner.png';
import BannerTop from "../Resource/BannerTop";
import UseCases from './UseCases';


export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <BannerTop />
      <div className='home-container'>
        <div className='home-cont1'>
          <div className='home-content1'>
            <div>
              <p><span className='gradient-text'><span>{t('home.digitalTwin')}</span></span></p>
              <p>{t('home.bannerText')}</p>
            </div>
            <div>
              <p className='tt'><span className='gradient-text'><span>{t('home.ourInnovation')}</span></span></p>
              <ul>
                <li>{t('home.predictPrices')}</li>
                <li>{t('home.standardProduct')}</li>
                {/* <li>{t('home.aiIntegration')}</li> */}
                <li>{t('home.onlineAccess')}</li>
              </ul>
            </div>
          </div>
          <div>
            <img src={HomeImg} alt='home-img' />
          </div>
        </div>

        <div className='home-cont2'>
          <p className='gradient-text'><span>{t('home.whatWeOffer')}</span></p>
          <div className='content-in-background'>
            <div>
              <img src={ScanBanner} alt='scan-banner-image' />
              <p className='p-offer'>{t('home.propertyScanning')}</p>
              <p className='p-offer'>{t('home.scanningDescription')}</p>
            </div>
            <div>
              <img src={DigitalBanner} alt='digital-baner-img' />
              <p className='p-offer'>{t('home.propertyDigitalization')}</p>
              <p className='p-offer'>{t('home.digitalizationDescription')}</p>
            </div>
          </div>
        </div>
    
 
      </div>
    </>
  );
}
