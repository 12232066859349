import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { getCurrentUser } from '../../API/apiService';
import { Link } from 'react-router-dom';
import PhotoBanner from '../../assets/Images/BannerImg.png';
import BannerMobileImg from '../../assets/Images/bannerMobile.jpg'
import { useTranslation } from 'react-i18next';

const BannerTop = () => {
  const { t, i18n } = useTranslation();

  return (
    <Box 
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        color: 'white',
        textAlign: 'center',
        padding: 2,
        overflow:'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: {xs : `url(${BannerMobileImg})`, md :  `url(${PhotoBanner})`},
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 1,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.13)', // Add a semi-transparent overlay
          zIndex: 2,
        },
        zIndex: 0,
      }}
    >
    <Typography
  variant="h3"
  gutterBottom
  sx={{
    position: 'relative',
    zIndex: 3,
    wordWrap: 'wrap',
    textWrap: 'wrap',
    marginTop: {xs : 32 , md:0},
    fontSize: {
      xs: '16px',  // For extra-small screens (mobile)
      sm: '18px',  // For small screens
      md: '42px',  // For medium screens and up
    },
    fontWeight: 400,
    color: '#fff',  // White color to contrast with the image
    textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',  // Subtle shadow for readability
    backgroundColor: 'rgba(0, 0, 0, 0.0)',  // Semi-transparent background
    padding: '8px 16px',  // Padding around the text
    borderRadius: '8px',  // Rounded corners for the background
  }}
>
  {t("banner.title")}
</Typography>

<Typography
  variant="subtitle1"
  gutterBottom
  sx={{
    position: 'relative',
    zIndex: 3,
    fontSize: {
      xs: '16px',  // For extra-small screens (mobile)
      sm: '18px',  // For small screens
      md: '20px',  // For medium screens and up
    },
    wordWrap: 'wrap',
    fontWeight: 400,
    color: '#fff',  // White color for the subtitle as well
    textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',  // Subtle shadow
    backgroundColor: 'rgba(0, 0, 0, 0.0)',  // Semi-transparent background
    padding: '8px 16px',  // Padding around the text
    borderRadius: '8px',  // Rounded corners for the background
  }}
>
{t("banner.desc")}</Typography>

      {getCurrentUser() === null && (
        <Box mt={2} 
        sx={{ position: 'relative', zIndex: 3 , display:'flex', gap:2}}>
            <Button
                  component={Link}
                  to='/login'
                  sx={{
                    background: 'none',
                    backgroundColor:'white',
                    '&:hover': {
                      backgroundColor:'white',
                      borderColor: '#3399ff',
                    },
                    borderRadius: '10px',
                    border: '2px solid white',
                    width: '110px',
                    height: '30px',
                    maxWidth: '250px',
                    textTransform: 'none',
                    padding: '18px', // Remove all padding
                    minWidth: 'auto', // Adjust to prevent default minWidth
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#163D6C',
                      padding: '0px' ,
                      fontWeight:'bold',
                    }}
                  >
                     {t("banner.login")}
                  </Typography>
                </Button>
                <Button
                  component={Link}
                  to='/register'
                  sx={{
                    background: 'none',
                    backgroundColor:'white',
                    '&:hover': {
                      backgroundColor:'white',
                      borderColor: '#3399ff'

                    },
                    borderRadius: '10px',
                    border: '2px solid white',
                    width: '110px',
                    height: '30px',
                    maxWidth: '250px',
                    textTransform: 'none',
                    padding: '18px', // Remove all padding
                    minWidth: 'auto', // Adjust to prevent default minWidth
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '15px',
                      color: '#163D6C',
                      padding: '0px' ,
                      fontWeight:'bold',
                    }}
                  >
                     {t("banner.register")}
                  </Typography>
                </Button>
        </Box>
      )}
    </Box>
  );
};

export default BannerTop;
