const ScanServiceModel = {
    ProjectName: '',
    BusinessName: '',
    ScanFile: false,
    GoogleMap: false,
    EmailBusiness : '',
    UserId: '',
    BookingMeeting: '',
    Status: '',
    
    PropertyInfoScan: {
        OwnerName: '',
        ObjectPhoto: '',
        SizeOfSpace: 0.0,
        SizeOfSite: 0.0,
        CadastralRegion: '',
        PropertyNumber: '',
        Volume: '',
        Page: '',
        NomenclatureMap: '',
        ReferencePrice: 0.0,
        Description: '',
        Address: {
            AddressLine: '',
            City: '',
            Country: '',
            GoogleMapLocation: '',
        },
        
        ScanningType: [
            {id: '', name: 'interior'}
        ],
        PropertyType: [
            { id: "", name: "residence" }
        ]
    },
    
    SiteContact: {
        Name: '',
        PhoneNumber: '',
        Email: ''
    },
    Payment:{
        totalPrice: '',
        paymentIntentId: 0
    }
};

export default ScanServiceModel;