import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { getForgeToken } from '../../API/apiService';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
const ForgeViewer2d = forwardRef(({ urn }, ref) => {
  const [viewer, setViewer] = useState(null);
  const [viewables, setViewables] = useState([]);
  const [selectedViewable, setSelectedViewable] = useState(null);
  const viewerRef = useRef(null);

  useEffect(() => {
    if (!urn) return;

    const initializeViewer = async () => {
      if (!viewerRef.current) return;

      const options = {
        env: 'AutodeskProduction',
        getAccessToken: getForgeToken
      };

      Autodesk.Viewing.Initializer(options, () => {
        const newViewer = new Autodesk.Viewing.GuiViewer3D(viewerRef.current); // Use Viewer2D here
        newViewer.start();
        setViewer(newViewer);

        const documentId = 'urn:' + urn;
        Autodesk.Viewing.Document.load(documentId, (doc) => {
          onDocumentLoadSuccess(doc, newViewer);
        }, onDocumentLoadFailure);
      });
    };

    initializeViewer();

    return () => {
      if (viewer) {
        viewer.finish();
      }
    };
  }, [urn]);

  const onDocumentLoadSuccess = (doc, viewerInstance) => {
    const root = doc.getRoot();
    const viewables = root.search({'type':'geometry', 'role':'2d'});
    if (viewables.length === 0) {
      return;
    }

    setViewables(viewables);
    setSelectedViewable(viewables[0]); // Automatically load the first viewable
    loadViewable(viewerInstance, viewables[0]);
  };

  const loadViewable = (viewerInstance, viewable) => {
    viewerInstance.loadDocumentNode(viewable.getDocument(), viewable).then(() => {
    });
  };

  const onDocumentLoadFailure = (viewerErrorCode) => {

  };


  const handleViewableChange = (event) => {
    const selected = viewables.find(viewable => viewable.data.name === event.target.value);
    setSelectedViewable(selected);
    loadViewable(viewer, selected);
  };

  return (
    <div>
    <FormControl 
      variant="outlined" 
      sx={{ margin: '15px',marginLeft:'0px', minWidth: '220px' }} 
      size="small"
    >
      <InputLabel 
        sx={{
          fontSize: "11px",
          padding: "8px",
          fontWeight: "600",
          marginLeft: "-0px",
          textAlign: "left"
        }} 
        id="viewable-select-label"
      >
        Select Viewable
      </InputLabel>
      <Select
        sx={{
          minWidth: '220px',
          padding: "2px", 
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          },
        }}
        labelId="viewable-select-label"
        value={selectedViewable?.data.name || ''}
        onChange={handleViewableChange}
        label="Select Viewable"
        MenuProps={{
          PaperProps: {
            sx: {
              width: '220px', // Ensure the dropdown menu is also 200px wide
            },
          },
        }}
      >
        {viewables.map((viewable) => (
          <MenuItem 
            key={viewable.data.guid} 
            value={viewable.data.name}
            sx={{ minWidth: '220px' }}
          >
            {viewable.data.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <div className='forge-container-2d' ref={viewerRef}></div>
  </div>
);
});

export default ForgeViewer2d;
