import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/Images/LogoWhite.png";
import Insta from "../../assets/Icons/insta.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  const email = import.meta.env.VITE_APP_ADMIN_EMAIL;  
  const subject = 'Support Request'; 
  const body = 'Hi, I need assistance with...';  
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-1">
          <div>
            <Link className="link" to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              href="https://www.instagram.com/virtu.nex?igsh=MWk0NXlveDF4enVheg=="
            >
              <img src={Insta} alt="insta-icon" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              href="https://www.linkedin.com/company/virtu-nex/"
            >
              <LinkedInIcon sx={{ color: "white", mt: 2, fontSize: 21 }} />
            </a>
          </div>
          <div>
          <a
          className="link"
           href={`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>
            <p>{t('footer.emailUs')}</p>
            </a>
          </div>
        </div>
        <div></div>
        <div>
          <p
            style={{ cursor: "default", fontWeight: "bold" }}
            className="link-to"
          >
            {t('footer.company')}
          </p>
          <Link className="link" to="/candidate">
            <p className="link-to">{t('footer.careers')}</p>
          </Link>
          <Link className="link" to="/schedule-meeting">
            <p className="link-to">{t('footer.bookDemo')}</p>
          </Link>
          {/* <Link className="link" to="/aboutUs">
            <p className="link-to">{t('footer.aboutUs')}</p>
          </Link> */}
        </div>
        <div>
          <Link className="link" to="/subscription/scan">
            <p className="link-to">{t('footer.subsPlan')}</p>
          </Link>
          {/* <Link className="link" to="/subscription-options">
            <p className="link-to">{t('footer.subscriptionsPlans')}</p>
          </Link> */}
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://chat.whatsapp.com/LKrm2XgzGSM6XhQNtWo9hi"
          >
            <p className="link-to">{t('footer.joinDiscord')}</p>
          </a>
        </div>
        <div>
          <p
            style={{ cursor: "default", fontWeight: "bold" }}
            className="link-to"
          >
            {t('footer.policies')}
          </p>
          <Link className="link" to="/privacy-policy">
            <p className="link-to">{t('footer.cookiesPrivacy')}</p>
          </Link>
          <Link className="link" to="/terms-condition">
            <p className="link-to">{t('footer.termsConditions')}</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
