import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './Locales/en/translation.json';
import alTranslation from './Locales/al/translation.json';
import {getCurrentLanguage} from '../src/Helpers/helpers'

const resources = {
  en: { translation: enTranslation },
  al: { translation: alTranslation }
};

i18n.use(initReactI18next).init({
  resources,
  lng: getCurrentLanguage(), // Default language
  fallbackLng: 'en', // Fallback language if translation is missing
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
