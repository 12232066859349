import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

function MyFallbackComponent({ error, resetErrorBoundary }) {
    return (
        <div>
            <p>Something went wrong:</p>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
}

export default MyFallbackComponent;