import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from '@mui/icons-material/Share';
import FilterListIcon from "@mui/icons-material/FilterList";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";
import { visuallyHidden } from "@mui/utils";
import { useState, useEffect } from "react";
import {
  addDigitalWorkLink,
  updateStatusDigital,
  addRevitFileUrn,
  cancelProjectAdmin,
  addDigitalPhotoUrl,
  deleteRealEstateDigital,
  addShareLinkDigital,
  notifyProjectCompleted
} from "../../../API/apiService";

import { GlobalContext } from "../../../GlobalContext/GlobalContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px", // Add this line for border radius
  boxShadow: 24,
  p: 4,
};

const rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "projectName",
    numeric: false,
    disablePadding: true,
    label: "Project Name",
  },

  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "projectLink",
    numeric: false,
    disablePadding: true,
    label: "Project Link",
  },
  { id: "workLink", numeric: false, disablePadding: false, label: "Work Link" },
  {
    id: "urn",
    numeric: false,
    disablePadding: true,
    label: "URN",
  },
  {
    id: "photoLink",
    numeric: false,
    disablePadding: true,
    label: "Photo Link",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "propertySize",
    numeric: false,
    disablePadding: false,
    label: "Property Size",
  },
  { id: "unit", numeric: false, disablePadding: false, label: "Unit" },
  {
    id: "interiorScope",
    numeric: false,
    disablePadding: false,
    label: "Interior Scope",
  },
  {
    id: "exteriorScope",
    numeric: false,
    disablePadding: false,
    label: "Exterior Scope",
  },
  { id: "landSize", numeric: false, disablePadding: false, label: "Site Size" },
  {
    id: "virtualTower",
    numeric: false,
    disablePadding: false,
    label: "Virtual Tour",
  },
  {
    id: "submissionTime",
    numeric: true,
    disablePadding: false,
    label: "Submission Time",
  },
  { id: "address", numeric: false, disablePadding: false, label: "Address" },
  {
    id: "revitVersion",
    numeric: false,
    disablePadding: false,
    label: "Revit Version",
  },
  {
    id: "subscription",
    numeric: false,
    disablePadding: false,
    label: "Connected with Subs",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all projects" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              key={headCell.id}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box key={headCell.id} component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false)
  const [openShareLinkModal, setOpenShareLinkModal] = useState(false)
  const [selectetProjectId, setSelectedProjectId] = useState("");
  const [shareLink, setShareLink] = useState("")
  const { numSelected, setSelected } = props;
  const handleCloseDeleteProjectModal = () => setOpenDeleteProjectModal(false);
  const handleCloseShareLinkModal = () => setOpenShareLinkModal(false);
  const { projectModified, setProjectModified } =
  React.useContext(GlobalContext);

 

  const handleOpenDeleteProjectModal = () => {
    setOpenDeleteProjectModal(true);
  };

  const handleOpenShareLinkModal = () => {
    setOpenShareLinkModal(true);
  };

  const deleteProject = async () => {
    try {
      const response = await deleteRealEstateDigital(numSelected[0]);
      setOpenDeleteProjectModal(false);
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenDeleteProjectModal(false);
      setSelected([]);
    }
  }

  const handleDeleteProject = async () => {
    if (numSelected[0] === "")
      return toast.error("Somethin went wrong.Please refresh the page");
    const response = deleteProject();
    toast.promise(
      response, 
      {
        loading: "Deleiting Project...",
        success: <b>Project deleted!</b>,
        error: <b>Deliting Project failed. Please try again.</b>,
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  }

  const addShareLink = async () => {
    try {
      const payload = {
        shareLink:shareLink
      }
      const response = await addShareLinkDigital(numSelected[0], payload);
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenShareLinkModal(false);
      setSelected([]);
    }
  }

  const handleAddShareLink = async () => {
    if (numSelected[0] === "")
      return toast.error("Somethin went wrong.Please refresh the page");
    if(shareLink === "")
      return toast.error("Please add the share link.")
    const response = addShareLink();
    toast.promise(
      response, 
      {
        loading: "Adding Share Link...",
        success: <b>Share link added!</b>,
        error: <b>Adding Share Link failed. Please try again.</b>,
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  }

  return (
    <>
      <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected.length > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected.length} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Projects
        </Typography>
      )}

      {numSelected.length > 0 ? (
        <>
        <Tooltip title="Delete">
          <IconButton >
            <DeleteIcon onClick= {() => handleOpenDeleteProjectModal()}/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Share">
          <IconButton >
            <ShareIcon onClick= {() => handleOpenShareLinkModal()}/>
          </IconButton>
        </Tooltip>
        </>
        
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
       
    </Toolbar>
    <Modal
        open={openDeleteProjectModal}
        onClose={handleCloseDeleteProjectModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Are you sure you want to delete this project?
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDeleteProject}
              sx={{ mr: 1 }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseDeleteProjectModal}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openShareLinkModal}
        onClose={handleCloseShareLinkModal}
        aria-labelledby="modal-work-link"
        aria-describedby="modal-work-link-description"
      >
        <Box sx={style}>
          <h2 style={{ marginBottom: 12 }} id="modal-work-link">
            Add Share Link
          </h2>
          <TextField
            InputLabelProps={{
              sx: {
                marginLeft: 0,
              },
            }}
            onChange={(e) => {
              setShareLink(e.target.value);
            }}
            label="Share link"
            variant="outlined"
            fullWidth
          />
          <Button
            onClick={handleAddShareLink}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
    </>
  
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("projectName");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { digitalProjects, projectModified, setProjectModified } =
    React.useContext(GlobalContext);
  const [copied, setCopied] = useState(false);

  const handleCopy = (urn) => {
    navigator.clipboard
      .writeText(urn)
      .then(() => {
        setCopied(true);
        toast.success("Urn copied");
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((err) => {
      });
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.projectId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    if (selected.includes(id)) {
      setSelected([]); // Deselect if the row is already selected
    } else {
      setSelected([id]); // Select only the new row
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(+event.target.value));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      digitalProjects.length > 0
        ? stableSort(digitalProjects, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : [], // Return an empty array if digitalProjects.data is empty
    [digitalProjects, order, orderBy, page, rowsPerPage]
  );
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);
  const [openRevitModal, setOpenRevitModal] = useState(false);
  const [openWorkModal, setOpenWorkModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openPhotoUrlModal, setOpenPhotoUrlModal] = useState(false);
  const [workLink, setWorkLink] = useState("");
  const [urn, setUrn] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [canceledReason, setCanceledReason] = useState("");
  const [selectetProjectId, setSelectedProjectId] = useState("");
  const [selectedProject, setSelectedProject] = useState({})
  const [selectedRow, setSelectedRow] = useState("");
  const [projectStatus, setProjectStatus] = useState("");

  const statusColors = {
    Draft: "#e7e7e7", // Light grey
    InProgress: "#dbf0fe", // Pale Blue
    Canceled: "#ffe3d4", // Light red
    Completed: "#e4f4e5", // Pale Green
  };

  const textStatusColor = {
    Draft: "#3D3D3D", // Light grey
    InProgress: "#1F4C89", // Pale Green
    Canceled: "#7E1310", // Light red
    Completed: "#254227",
  };

  const handleOpenRevitModal = (row) => {
    setSelectedProjectId(row.projectId);
    setSelectedProject(row);
    setOpenRevitModal(true);
  };

  const handleCloseRevitModal = () => setOpenRevitModal(false);
  const handleCloseCancelModal = () => setOpenCancelModal(false);
  const handleCloseStatusModal = () => setOpenStatusModal(false);
  const handleClosePhotoUrlModal = () => setOpenPhotoUrlModal(false);

  const handleOpenWorkModal = (row) => {
    setSelectedProjectId(row.projectId);
    setSelectedProject(row);
    setOpenWorkModal(true);
  };

  const handleOpenStatusModal = (rowId, status) => {
    setSelectedProjectId(rowId);
    setProjectStatus(status);
    setOpenStatusModal(true);
  };

  const handleOpenCancelModal = (row) => {
    setSelectedProjectId(row.projectId);
    setSelectedRow(row);
    setOpenCancelModal(true);
  };

  const handleOpenPhotoUrlModal = (rowId) => {
    setSelectedProjectId(rowId);
    setOpenPhotoUrlModal(true);
  };

  const addWorkLink = async () => {
    const payload = {
      workLink: workLink,
    };
    try {
      const response = await addDigitalWorkLink(selectetProjectId, payload);
      if(response?.success){
        const notifyPayload = {
          projectId: selectedProject.projectId,
          projectName: selectedProject.projectName,
          userId: selectedProject.userId,
          serviceType: "digital",
          isUpdated: !!selectedProject.workLink
        };
        await notifyProjectCompleted(notifyPayload);
      }
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenWorkModal(false);
      setWorkLink("");
    }
  };

  const handleAddWorkLink = async () => {
    if (selectetProjectId === "") return toast.error("Somethin went wrong.");
    if (workLink === "") return toast.error("Please enter the work link");
    const response = addWorkLink();

    toast.promise(
      response,
      {
        loading: "Adding work link...",
        success: <b>Work link added succesfuly!</b>,
        error: <b>Adding work link failed. Please try again.</b>,
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  const handleChangeStatus = async () => {
    if (selectetProjectId === "") return toast.error("Somethin went wrong.");
    if (!(projectStatus === "InProgress" || projectStatus === "Draft"))
      return toast.error("Current project status is not valid for update");
    const payload = {
      newStatus: projectStatus === "InProgress" ? "Draft" : "InProgress",
    };
    try {
      const response = await updateStatusDigital(selectetProjectId, payload);

      setOpenStatusModal(false);
      setProjectModified(!projectModified);
      toast.success("Status changed successfuly");
    } catch (error) {
    } finally {
      setOpenWorkModal(false);
    }
  };

  const addPhotoUrl = async () => {
    const payload = {
      photoUrl: photoUrl,
    };

    try {
      const response = await addDigitalPhotoUrl(selectetProjectId, payload);
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenPhotoUrlModal(false);
      setPhotoUrl("");
    }
  };

  const handleAddPhotoUrl = async () => {
    if (selectetProjectId === "")
      return toast.error("Somethin went wrong.Please refresh the page");
    if (photoUrl === "") return toast.error("Please enter the photo link");
    const response = addPhotoUrl();
    toast.promise(
      response,
      {
        loading: "Adding photo link...",
        success: <b>Photo link added succesfuly!</b>,
        error: <b>Adding photo link failed. Please try again.</b>,
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  const cancelProject = async () => {
    const payload = {
      newStatus: "Canceled",
      userId: selectedRow.userId,
      canceledReason: canceledReason,
      serviceType: "Digital",
      projectName: selectedRow.projectName,
    };

    try {
      const response = await cancelProjectAdmin(selectetProjectId, payload);
      setOpenCancelModal(false);
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenCancelModal(false);
      setCanceledReason("");
    }
  };

  const handleCancelProject = async () => {
    if (selectetProjectId === "") return toast.error("Somethin went wrong.");
    if (canceledReason === "")
      return toast.error(
        "Please enter the the reason why you want to cancel the project."
      );
    const response = cancelProject();

    toast.promise(
      response,
      {
        loading: "Canceling the order...",
        success: "Order canceled. User was notify via mail!",
        error: "Canceling the order failed. Please try again.",
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  const addRevitFileURN = async () => {
    const payload = {
      urn: urn,
    };
    try {
      const response = await addRevitFileUrn(selectetProjectId, payload);
      if(response?.success && selectedProject.urn){
        toast.success("notify")
        const notifyPayload = {
          projectId: selectedProject.projectId,
          projectName: selectedProject.projectName,
          userId: selectedProject.userId,
          serviceType: "digital",
          isUpdated: !!selectedProject.workLink,
          isUrnUpdated: true
        };
        await notifyProjectCompleted(notifyPayload);
      }
      setProjectModified(!projectModified);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOpenRevitModal(false);
      setUrn("");
    }
  };
  const handleAddRevitFileUrn = async () => {
    if (selectetProjectId === "") return toast.error("Something went wrong.");
    if (urn === "") return toast.error("Please enter the revit file urn");
    const response = addRevitFileURN();

    toast.promise(
      response,
      {
        loading: "Adding revit file urn...",
        success: <b>Revit file urn added.</b>,
        error: <b>Adding revit file urn failed. Please try again.</b>,
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  const handleCloseWorkModal = () => setOpenWorkModal(false);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected} setSelected={setSelected} />
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.projectId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <>
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.projectId)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.projectId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          sx={{ marginTop: 1.5 }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.projectName}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        <a
                          href={row.projectUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.projectUrl ? "Project Link" : ""}
                        </a>
                      </TableCell>

                      <TableCell align="left">
                        <a
                          href={row.workLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.workLink ? "Work Link" : ""}
                        </a>
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer", position: "relative" }}
                        onClick={() => handleCopy(row?.urn)}
                        align="left"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        {row?.urn?.length > 7
                          ? row?.urn?.slice(0, 7) + "..."
                          : row?.urn}

                        {showTooltip && (
                          <div
                            style={{
                              position: "absolute",
                              bottom: "70%",
                              left: "0",
                              padding: "5px",
                              backgroundColor: "grey",
                              color: "#fff",
                              borderRadius: "4px",
                              whiteSpace: "nowrap",
                              zIndex: 1,
                            }}
                          >
                            {row?.urn}
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <a
                          href={row.photoUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.photoUrl ? "Photo Link" : ""}
                        </a>
                      </TableCell>
               
                      <TableCell align="left">
                        <Typography
                          style={{
                            textWrap: "nowrap",
                            fontSize: "13px",
                            padding: "5px",
                            borderRadius: "6px",
                            textAlign: "center",
                            backgroundColor: statusColors[row.status],
                            color: textStatusColor[row.status],
                          }}
                        >
                          {" "}
                          {row.status === "InProgress"
                            ? "In Progress"
                            : row.status}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        {row.propertyInfoDigital.propertySize}
                      </TableCell>
                      <TableCell align="left">
                        {row.propertyInfoDigital.unit}
                      </TableCell>
                      <TableCell align="left">
                        {row.propertyInfoDigital.interiorScope}
                      </TableCell>
                      <TableCell align="left">
                        {row.propertyInfoDigital.exteriorScope}
                      </TableCell>
                      <TableCell align="left">
                        {row.propertyInfoDigital.landSize}
                      </TableCell>
                      <TableCell align="left">
                        <a
                          href={row.propertyInfoDigital.virtualTower}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.propertyInfoDigital.virtualTower
                            ? "Virtual Tour Link"
                            : ""}
                        </a>
                      </TableCell>

                      <TableCell align="right">{row.submissionTime}</TableCell>
                      <TableCell align="left">
                        {row.propertyInfoDigital.address}
                      </TableCell>
                      <TableCell align="left">
                        {row.propertyInfoDigital.revitVersion}
                      </TableCell>
                      <TableCell align="left">
                        {row?.subscriptionId !== null ? "Yes" : "No"}
                      </TableCell>  
                      {row.status !== "Canceled" && (
                        <TableCell
                          sx={{ display: "flex", height: "auto", gap: 2 }}
                          align="center"
                        >
                          {(row.status === "InProgress" ||
                            row.status === "Draft") && (
                            <Button
                              sx={{
                                fontSize: 12,
                                textTransform: "none",
                                width: 120,
                                display: "flex",
                              }}
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                handleOpenStatusModal(
                                  row.projectId,
                                  row.status
                                );
                              }}
                            >
                              {row.status === "InProgress"
                                ? "Back to status draft"
                                : "Mark as in progress"}
                            </Button>
                          )}
                          <Button
                            sx={{
                              fontSize: 13,
                              textTransform: "none",
                              width: 120,
                              color: "#002984",
                            }}
                            variant="outlined"
                            color="inherit"
                            onClick={() => {
                              handleOpenPhotoUrlModal(row.projectId);
                            }}
                          >
                            {row.photoUrl
                              ? "Update photo link"
                              : "Add photo link"}
                          </Button>
                          <Button
                            sx={{
                              fontSize: 13,
                              textTransform: "none",
                              width: 120,
                            }}
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                              handleOpenRevitModal(row);
                            }}
                          >
                            {row?.urn ? "Update URN" : " Add urn of revit file"}
                          </Button>
                          <Button
                            sx={{
                              fontSize: 13,
                              textTransform: "none",
                              width: 120,
                            }}
                            variant="outlined"
                            color="success"
                            onClick={() => {
                              handleOpenWorkModal(ow);
                            }}
                          >
                            {row.workLink
                              ? "Update work link "
                              : "Add work link"}
                          </Button>
                          {row.status === "Draft" && (
                            <Button
                              sx={{
                                fontSize: 13,
                                textTransform: "none",
                                width: 120,
                              }}
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                handleOpenCancelModal(row);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  </>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 0,
          }}
        >
          <Box>
            <Switch
              sx={{ mt: 0 }}
              checked={dense}
              onChange={handleChangeDense}
            />
            Dense padding
          </Box>

          <TablePagination
            sx={{
              display: "flex",
              justifyContent: "center", // Centers horizontally
              alignItems: "center", // Centers vertically
              "& .MuiTablePagination-actions": {
                display: "flex",
                alignItems: "center", // Ensures the arrows are centered
                mt: 1.5,
              },
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={digitalProjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
      <Modal
        open={openRevitModal}
        onClose={handleCloseRevitModal}
        aria-labelledby="modal-scan-link"
        aria-describedby="modal-scan-link-description"
      >
        <Box sx={style}>
          <h2 style={{ marginBottom: 12 }} id="modal-scan-link">
            Add Revit File Urn
          </h2>
          <TextField
            InputLabelProps={{
              sx: {
                marginLeft: 0,
              },
            }}
            onChange={(e) => setUrn(e.target.value)}
            label="Revit URN"
            variant="outlined"
            fullWidth
          />
          <Button
            onClick={handleAddRevitFileUrn}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openWorkModal}
        onClose={handleCloseWorkModal}
        aria-labelledby="modal-work-link"
        aria-describedby="modal-work-link-description"
      >
        <Box sx={style}>
          <h2 style={{ marginBottom: 12 }} id="modal-work-link">
            Add Work Link
          </h2>
          <TextField
            InputLabelProps={{
              sx: {
                marginLeft: 0,
              },
            }}
            onChange={(e) => {
              setWorkLink(e.target.value);
            }}
            label="Work Link"
            variant="outlined"
            fullWidth
          />
          <Button
            onClick={handleAddWorkLink}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openCancelModal}
        onClose={handleCloseCancelModal}
        aria-labelledby="modal-work-link"
        aria-describedby="modal-work-link-description"
      >
        <Box sx={style}>
          <h2
            style={{ marginBottom: 12, fontSize: "16px" }}
            id="modal-work-link"
          >
            Enter the reason why you want to cancel this project:
          </h2>
          <TextField
            InputLabelProps={{
              sx: {
                marginLeft: 0,
              },
            }}
            onChange={(e) => {
              setCanceledReason(e.target.value);
            }}
            label="Reason"
            variant="outlined"
            fullWidth
          />

          <Button
            onClick={handleCancelProject}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openPhotoUrlModal}
        onClose={handleClosePhotoUrlModal}
        aria-labelledby="modal-work-link"
        aria-describedby="modal-work-link-description"
      >
        <Box sx={style}>
          <h2 style={{ marginBottom: 12 }} id="modal-work-link">
            Add Photo Link
          </h2>
          <TextField
            InputLabelProps={{
              sx: {
                marginLeft: 0,
              },
            }}
            onChange={(e) => {
              setPhotoUrl(e.target.value);
            }}
            label="Photo link"
            variant="outlined"
            fullWidth
          />
          <Button
            onClick={handleAddPhotoUrl}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
      <Modal
        open={openStatusModal}
        onClose={handleCloseStatusModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h6" component="h2">
            Are you sure you want to change the project status to "In Progress"?
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleChangeStatus}
              sx={{ mr: 1 }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseStatusModal}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
