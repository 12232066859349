
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '../../assets/Icons/InfoIcon.png';
import { useTranslation } from 'react-i18next';
import { maxCharactersValidator } from '../../Validators/validator';

const ValidatedTextField = ({ label, type, fieldName, parent, validator,value,maxCharacters = 0,
                             tooltip = 'propertyInfoScan.tooltip', 
                             onChange, optional = false, showAdornment = true, maxValue = 0 }) => {
  const [error, setError] = useState("");
  const { t } = useTranslation();                              
  const handleChange = e => {
    const newValue = e.target.value;
    let errorMessage = '';
      


    if(fieldName === 'propertySize'|| fieldName === 'landSize' || fieldName === 'SizeOfSpace' || fieldName === 'SizeOfSite'){
       errorMessage += validator(newValue, maxValue, fieldName);
    }
    else{
      if (maxCharacters > 0) {
        errorMessage = maxCharactersValidator(newValue, maxCharacters);
      }
    
      const additionalError = validator(newValue);
      if (additionalError) {
        errorMessage += (errorMessage ? '<br />' : '') + additionalError;
      }
       errorMessage += validator(newValue);
    }
    
    setError(errorMessage);
    onChange(e, parent, fieldName);
  };
  return (
    <TextField
    label={`${t(label)}${optional ? ` ${t('propertyInfoScan.optional')}` : ''}`}
    variant="outlined"
    value={value}
    size='small'
    helperText={<span dangerouslySetInnerHTML={{ __html: error }} />}
    error={!!error}
    name={fieldName}
    onChange={handleChange}
    type={type}
    fullWidth
    sx={{
      '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        fontSize: '12px',
        padding: '6px'
      },
      width: { xs: '300px', md: '400px' },
    }}
    InputProps={{
      fontSize: '12px',
      padding: '14px',
      endAdornment: showAdornment && (
        <Tooltip
          title={t(tooltip)}
          arrow
          PopperProps={{
            disablePortal: true, // Ensure tooltip is rendered within the DOM hierarchy of the TextField
            style: { marginTop: '8px' } // Adjust the margin-top as needed to position the tooltip correctly
          }}
        >
          <IconButton>
            <img style={{ width: '15px' }} src={InfoIcon} alt="info-icon" />
          </IconButton>
        </Tooltip>
      ),
    }}
    InputLabelProps={{
      sx: {
        fontSize: '11px',
        fontWeight: '600',
        fontFamily: 'Roboto, sans-serif',
        padding: '8px',
        marginLeft: '-3px',
        shrink: true,
      },
    }}
    style={{ marginBottom: 16, fontSize: '12px' }}
  />
);
};
export default ValidatedTextField;
