import React, { useState, useEffect } from "react";
import DownloadIcon from "../../assets/Images/downloadIcon.png";
import { useParams } from "react-router-dom";
import { getScanProjectById } from "../../API/apiService";
import backIcon from "../../assets/Icons/left-chevron.png";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

export default function OrderDetailsScan() {
  const [projectScan, setProjectScan] = useState([]);
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const propInfo = projectScan?.propertyInfoScan;
  const [loading, setLoading] = useState(false);
  let { orderId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const toastId = toast.loading(t("orderDetailsScan.loadingMessage"));

    try {
      const projectScan = async () => {
        const result = await getScanProjectById(orderId);
        setProjectScan(result.data);
      };

      projectScan();
    } catch (error) {
      // Handle error if necessary
    } finally {
      toast.dismiss(toastId);
    }
  }, [orderId, t]);

  const propertyTypeExist = (propType) => {
    const spaces = projectScan?.propertyInfoScan?.propertyType;
    return spaces ? spaces.some((prop) => prop.type === propType) : false;
  };

  const scanningTypeExist = (scanningType) => {
    const scanTypes = projectScan?.propertyInfoScan?.scanningType;
    return scanTypes
      ? scanTypes.some((scanType) => scanType.type === scanningType)
      : false;
  };

  const exteriorScopeExist = (exteriorScope) => {
    const extScope = projectScan?.propertyInfoScan?.exteriorScope;
    return extScope
      ? extScope.some((exterior) => exterior.type === exteriorScope)
      : false;
  };


  return (
    <div className="top-od-cont">
      <img
        onClick={() => navigate("/orders/scan")}
        style={{ width: "30px", height: "30px", cursor: "pointer" }}
        src={backIcon}
        alt="back-icon"
      />
      <p className="gradient-text">
        <span>{t("orderDetailsScan.orderDetailsTitle")}</span>
      </p>
      {projectScan && (
        <div className="order-details-container">
          {projectScan?.status === "Completed" && (
            <div className="">
              <iframe
                className="content-model"
                src={projectScan.scanLink || "about:blank"} // Fallback to 'about:blank'
                allow="fullscreen; autoplay=0"
                style={{ border: "none" }}
              ></iframe>
              {/* <img
                className="content-model"
                src={projectScan?.photoUrl || imgtest}
                alt="content-3d"
              />
              <div className="extendIcon-cont">
                <img
                  onClick={() => setShareDialogOpen(!isShareDialogOpen)}
                  src={RedirectIcon}
                  alt="redirect-icon"
                />
                <ShareDialog
                  open={isShareDialogOpen}
                  onClose={() => setShareDialogOpen(false)}
                  url={shareUrl}
                  title={shareTitle}
                />
              </div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={projectScan.scanLink}
              >
                <div className="open-project-scan">
                  <img src={VideoPlayIcon} alt="video-play-icon" />
                </div>
              </a> */}
            </div>
          )}
          {(projectScan?.status === "InProgress" || projectScan?.status === "Canceled" ) && (
            <>
              <div className="message-container">
              <div className="message-header">
                  {projectScan?.status !== "Canceled" &&
          (
            t("orderDetailsScan.orderInProgress")
          )
                    }
                </div>
                <div className="message-body">
                  {projectScan?.status === "Canceled"
                    ? t("orderDetailsScan.orderCanceledMessage")
                    : t("orderDetailsScan.orderProcessingMessage")}
                </div>
                <div className="message-footer">
                  {projectScan?.status !== "Canceled" &&
                    t("orderDetailsScan.appreciationMessage")}
                </div>
              </div>
            </>
          )}
              {projectScan?.status === "Draft" && (
            <>
              <div className="message-container">
                <div className="message-header">
                  {projectScan?.status !== "Canceled" &&
                    t("orderDetailsScan.thankYouMessage")}
                </div>
                <div className="message-body">
                     {t("orderDetailsScan.awaitingPayment")}
                </div>
              </div>
            </>
          )}
          <div className="order-details-content">
            <p className="proj-name-st">{projectScan.projectName}</p>
            <p className="proj-address-st">
              {projectScan.propertyInfoScan?.address?.addressLine}
            </p>
            <p className="proj-title-st">
              {t("orderDetailsScan.scanningDetailsTitle")}
            </p>
            <div className="od-types-cont">
              <div className="od-space-cont">
                <div>
                  <p>{t("orderDetailsScan.spaceTypeLabel")}</p>
                </div>
                <div className="space-type-cont">
                  <p
                    className={`space-type${
                      propertyTypeExist("Residencial") ? "-exist" : "-notExist"
                    }`}
                  >
                    {t("orderDetailsScan.residentialType")}
                  </p>
                  <p
                    className={`space-type${
                      propertyTypeExist("Commercial") ? "-exist" : "-notExist"
                    }`}
                  >
                    {t("orderDetailsScan.commercialType")}
                  </p>
                  <p
                    className={`space-type${
                      propertyTypeExist("Industrial") ? "-exist" : "-notExist"
                    }`}
                  >
                    {t("orderDetailsScan.industrialType")}
                  </p>
                  <p
                    className={`space-type${
                      propertyTypeExist("Institutional")
                        ? "-exist"
                        : "-notExist"
                    }`}
                  >
                    {t("orderDetailsScan.institutionalType")}
                  </p>
                </div>

                <div>
                  <p>{t("orderDetailsScan.spaceSizeLabel")}</p>
                  <p>
                    {projectScan.propertyInfoScan?.sizeOfSpace}/
                    {projectScan.propertyInfoScan?.sizeOfSite} m²
                  </p>
                </div>
              </div>

              <div className="od-model-cont">
                <div>
                  <p>{t("orderDetailsScan.modelScopeLabel")}</p>
                </div>

                <div className="model-type">
                  <p
                    className={`space-type${
                      scanningTypeExist("Interior Virtual Tour")
                        ? "-exist"
                        : "-notExist"
                    }`}
                  >
                    {t("orderDetailsScan.interiorType")}
                  </p>
                  <p
                    className={`space-type${
                      scanningTypeExist("Exterior Virtual Tour")
                        ? "-exist"
                        : "-notExist"
                    }`}
                  >
                    {t("orderDetailsScan.exteriorType")}
                  </p>
                  <p
                    className={`space-type${
                      scanningTypeExist("Site Virtual Tour")
                        ? "-exist"
                        : "-notExist"
                    }`}
                  >
                    {t("orderDetailsScan.siteType")}
                  </p>
                </div>
                <a
                  className="link"
                  href={projectScan.workLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tooltip title={t("orderDetailsScan.downloadTooltip")}>
                    <div className="btn-download-scan">
                      <img
                        style={{
                          width: "14px",
                          height: "15px",
                          marginRight: "7px",
                        }}
                        src={DownloadIcon}
                        alt="download-icon"
                      />
                      {t("orderDetailsScan.downloadLabel")}
                    </div>
                  </Tooltip>
                </a>
              </div>
            </div>
            <div className="order-details-tab">
              <p>{t("orderDetailsScan.detailsTitle")}</p>
              <hr />
              <p className="pd-title">{t("orderDetailsScan.ownerNameLabel")}</p>
              <p className="pd-info">
                {propInfo?.ownerName === ""
                  ? t("orderDetailsScan.NA")
                  : propInfo?.ownerName}
              </p>
              <hr />
              <p className="pd-title">
                {t("orderDetailsScan.propertyNumberLabel")}
              </p>
              <p className="pd-info">
                {propInfo?.propertyNumber === ""
                  ? t("orderDetailsScan.NA")
                  : propInfo?.propertyNumber}
              </p>
              <hr />
              <p className="pd-title">
                {t("orderDetailsScan.cadasterZoneLabel")}
              </p>
              <p className="pd-info">
                {propInfo?.cadastralRegion === ""
                  ? t("orderDetailsScan.NA")
                  : propInfo?.cadastralRegion}
              </p>
              <hr />
              <p className="pd-title">{t("orderDetailsScan.pageLabel")}</p>
              <p className="pd-info">
                {propInfo?.page === ""
                  ? t("orderDetailsScan.NA")
                  : propInfo?.page}
              </p>
              <hr />
              <p className="pd-title">{t("orderDetailsScan.volumeLabel")}</p>
              <p className="pd-info">
                {propInfo?.volume === ""
                  ? t("orderDetailsScan.NA")
                  : propInfo?.volume}
              </p>
              <hr />
              <p className="pd-title">{t("orderDetailsScan.mapLegendLabel")}</p>
              <p className="pd-info">
                {propInfo?.nomenclatureMap === ""
                  ? t("orderDetailsScan.NA")
                  : propInfo?.nomenclatureMap}
              </p>
              <hr />
              <p className="pd-title">
                {t("orderDetailsScan.referencePriceLabel")}
              </p>
              <p className="pd-info">
                {propInfo?.referencePrice === ""
                  ? t("orderDetailsScan.NA")
                  : propInfo?.referencePrice}
              </p>
              <hr />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
