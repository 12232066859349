import React, { useState } from "react";
import FieldRegister from '../Orders/Auth/FieldRegister';
import outMsg from '../../assets/Icons/outMsg.png';
import { Link } from "react-router-dom";
import Logo from '../../assets/Images/Logo.png';
import KeyReset from '../../assets/Icons/keyReset.png';
import { Button } from "@mui/material";
import { resetPasswordRequest } from '../../API/apiService';
import toast from "react-hot-toast";
import { emailValidator } from "../../Validators/validator";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function ResetPassword() {
    const { t } = useTranslation(); // Use translation hook
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (event, parent, child) => {
        const value = event.target.value;
        setEmail(value);
    };

    const handlePasswordResetRequest = async (event) => {
        event.preventDefault();
        if (emailValidator(email) !== '') {
            toast.error(t('resetPassword.emailPlaceholder')); // Use translation for validation error
            return;
        }

        try {
            const response = await resetPasswordRequest({
                email:email
            });
            if (response.success) {
                navigate('/reset-link-sent');
                return;
            } 
          
        } catch (error) {

        }finally{
            setEmail('')
        }
    };

    return (
        <div className="reset-pass-container">
            <div className="reset-pass-content">
                <div>
                    <img src={Logo} alt="logo-img" />
                </div>
                <div className="reset-pass-form">
                    <img src={KeyReset} alt="key-icon" />
                    <h3>{t('resetPassword.title')}</h3>
                    <p className="reset-info">{t('resetPassword.info')}</p>
                    <FieldRegister
                        label={t('resetPassword.emailPlaceholder')}
                        type='email'
                        fieldName='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        icon={outMsg}
                        validator={emailValidator}
                    />
                    <Button
                        onClick={handlePasswordResetRequest}
                        sx={{
                            padding: '12px',
                            fontSize: '12px',
                            fontWeight: 400,
                            borderRadius: '10px',
                            marginTop: '-15px',
                            border: 'none',
                            backgroundColor: '#1a477f',
                            color: 'white',
                            cursor: 'pointer',
                            textTransform: 'none',
                            width: { xs: '300px', md: '400px' },
                            '&:hover': {
                                backgroundColor: '#163D6C',
                            }
                        }}
                    >
                        {t('resetPassword.continue')}
                    </Button>
                    <Link className="link" to='/login'>
                        <p className="back-to-login">{t('resetPassword.backToLogin')}</p>
                    </Link>
                    <div className="terms-cont">
                        <p className="p-terms">
                            <Link className="link" to='/terms-Condition'>
                                <span>{t('resetPassword.termsOfUse')}</span>
                            </Link>
                            <span> &nbsp; | &nbsp; </span>
                            <Link className="link" to='/privacy-policy'>
                                <span>{t('resetPassword.privacyPolicy')}</span>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
