import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/Images/Logo.png";
import CongratsImg from "../../assets/Images/Congrats.png";
import { confirmEmailAsync } from "../../API/apiService";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function CongratsRegister() {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Parse query parameters
    setLoading(true);
    const toastId = toast.loading();
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    const token = params.get("token");

    if (email && token) {
      // Call the API to confirm email
      const confirmEmail = async () => {
        try {
          const payload = { email, token };
          const response = await confirmEmailAsync(payload);
          if (response.success) {
            setSuccess(true);
          } else {
            toast.error(response.data.errorMessage);
          }
        } catch (error) {
          setSuccess(false);
         // toast.error(error.response.errorMessage);
        } finally {
          toast.dismiss(toastId);
          setLoading(false);
        }
      };

      confirmEmail();
    } else {
      toast.dismiss(toastId);
      setLoading(false);
    }
  }, [location]);

  return (
    <>
      {!loading && (
        <div className="congrats-register-container">
          <div className="congrats-reg-content">
            <div>
              <img src={Logo} alt="logo-img" />
            </div>
            {success ? (
              <div className="congrats-reg-form">
                <img src={CongratsImg} alt="congrats-img" />
                <p className="reset-info">
                  {t('congratsRegister.successMessage')}
                </p>
                <Button
                  component={Link}
                  to="/login"
                  sx={{
                    padding: "12px",
                    fontSize: "12px",
                    fontWeight: 400,
                    borderRadius: "10px",
                    marginTop: "20px",
                    border: "none",
                    backgroundColor: "#1a477f",
                    color: "white",
                    cursor: "pointer",
                    textTransform: "none",
                    width: { xs: "300px", md: "400px" },
                    "&:hover": {
                      backgroundColor: "#163D6C",
                    },
                  }}
                >
                  {t('congratsRegister.continueButton')}
                </Button>
              </div>
            ) : (
              <div>
                <p>{t('congratsRegister.errorMessage')}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
