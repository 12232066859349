

 const DigitalServiceModel = {
    projectName: '',
    projectUrl: '',
    email: '',
    status: '',
    userId: '',
    submissionTime: '2024-07-09T10:02:37.135Z',
    propertyInfoDigital: {
      propertySize: 0,
      unit: 'imperial',
      landDigital: false,
      exteriorDigital: false,
      landSize: 0,
      virtualTower: '',
      address: '',
      revitVersion: '',
      propertyType: [
      {id: '241e5c0d-a1e0-4d55-9213-b53ef4316551', type: 'standart'}
      ],
      interiorScope: [
        {id:'887ffbc3-00e7-449e-ab3f-02803c0d0054a', type: 'architecture'}
      ],
      exteriorScope: [
        {id:'887ffbc3-00e7-449e-ab3f-02803c0d0054a', type: 'architecture'}
      ]   
    },
    payment: {
      totalPrice: 0,
      paymentIntendId: ''
    },
    workingDays:[
      
    ]
  };
  
  // Example usage
  export default DigitalServiceModel;
  