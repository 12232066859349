export const pricingScan = {
    Interior: {
      Residentiale: 0.3,
      Commerciale: 0.4,
      Industriale: 0.5,
      Institucionale: 0.6
    },
    Exterior: {
      Residentiale: 0.2,
      Commerciale: 0.3,
      Industriale: 0.4,
      Institucionale: 0.5
    },
    Site: {
      Residentiale: 0.1,
      Commerciale: 0.2,
      Industriale: 0.3,
      Institucionale: 0.4
    }
  };
  

  