import React, { useEffect } from 'react';

const CalendlyWidget = ({ scan  }) => {
  useEffect(() => {
    // Dynamically create and append the Calendly script
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
    // Cleanup the script when component unmounts (optional)
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className={`calendly-inline-widget ${scan === false ?'calendar-cont' : 'calendar-scan'}`}
      data-url="https://calendly.com/virtu-nex-info"
     
    />
  );
};

export default CalendlyWidget;
