import React, { useState, useEffect } from "react";
import ScanBannerImage from "../../assets/Images/scanBanner.png";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  IconButton
} from "@mui/material";
import dayjs from "dayjs";
import PropertyInfoScan from "./PropertyScanInformation";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import initialRealEstateScan from "../../Models/ScanServiceModel";
import ValidatedTextField from "./ValidateTextField";
import excelIcon from "../../assets/Icons/excel.png";
import { useTranslation } from "react-i18next";
import {
  textValidator,
  emailValidator,
  phoneNumberValidator,
  requiredValidator,
  noValidator,
  validatorWithMaxValue,
} from "../../Validators/validator";
import PricingCard from "../PricingCard";
import "dayjs/locale/en";
import "dayjs/locale/sq";


export default function RealEstateScan() {
  const [realEstateScan, setRealEstateScan] = useState(initialRealEstateScan);
  const [formattedDateTime, setFormattedDateTime] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
  const [errors, setErrors] = useState("");
  const [disabledDates, setDisabledDates] = useState([]);

  // useEffect(() => {

  //   const currentUser = getCurrentUser();
  //   const getDisableDates = async () => {

  //     try {
  //       const response = await getAllDisableDates();

  //       // Convert the dates in response to dayjs objects
  //       const convertedArray = response.data.map(item => ({
  //         id: item.id,
  //         disabledDate: dayjs(item.disableDate)
  //       }));

  //       setDisabledDates(convertedArray);
  //     } catch (error) {
  //     } finally {

  //     }
  //   };
  //   if(currentUser !== null){
  //       getDisableDates();
  //   }

  // }, []);

  const [propertyType, setPropertyType] = useState({
    residence: true,
    commercial: false,
    institutional: false,
    industrial: false,
  });

  const [scanType, setScanType] = useState({
    interior: true,
    exterior: false,
    site: false,
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFieldsError = (err) => {
    setErrors(err);
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    if (name === "Country") {
      setRealEstateScan((prevState) => ({
        ...prevState,
        PropertyInfoScan: {
          ...prevState.PropertyInfoScan,
          BusinessName: "",
          EmailBusiness: "",
          Address: {
            ...prevState.PropertyInfoScan.Address,
            Country: value,
          },
        },
      }));
    }

    if (name === "Country" && value === "Albania") {
      // setRealEstateScan((prevState) => ({
      //   ...prevState,
      //   ScanFile: true,
      // }));
    }
    if (name === "Country" && value === "Kosovo") {
      setRealEstateScan((prevState) => ({
        ...prevState,
        ScanFile: false,
        GoogleMap: true,
        PropertyInfoScan: {
          ...prevState.PropertyInfoScan,
          PropertyType: [{ id: "", name: "commercial" }],
        },
      }));
      if(propertyType.residence === true){
           setPropertyType({
        residence: false,
        commercial: true,
        institutional: false,
        industrial: false,
      });
      }
   
    }
    if (name === "City") {
      setRealEstateScan((prevState) => ({
        ...prevState,
        PropertyInfoScan: {
          ...prevState.PropertyInfoScan,
          Address: {
            ...prevState.PropertyInfoScan.Address,
            City: value,
          },
        },
      }));
    }
  };

  const handleOptionChange = (event) => {
    const googleMap = event.target.value;
    setRealEstateScan((prevState) => ({
      ...prevState,
      GoogleMap: googleMap === "Yes",
    }));
  };

  const handleScanFileChange = (event) => {
    const scanFile = event.target.value;
    setRealEstateScan((prevState) => ({
      ...prevState,
      ScanFile: scanFile === "Yes",
    }));
  };
  const handleDateChange = (newValue) => {
    if (!newValue || !newValue.isValid()) {
      return;
    }

    // Format the date-time to 'DD/MM/YYYY HH:mm'
    const formatted = newValue.format("DD/MM/YYYY HH:mm");
    setFormattedDateTime(formatted);
    setSelectedDateTime(newValue);

    setRealEstateScan((prevState) => ({
      ...prevState,
      BookingMeeting: formatted,
    }));
  };

  const handleNestedInputChange = (event, parent, child) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (child === "BusinessName" || child === "EmailBusiness") {
      setRealEstateScan((prevState) => ({
        ...prevState,
        [child]: value,
      }));
    } else {
      let parsedVale = value;
      if (
        child === "SizeOfSpace" ||
        child === "SizeOfSite" ||
        child === "ReferencePrice"
      ) {
        parsedVale = parseFloat(value);
      }
      setRealEstateScan((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: parsedVale,
        },
      }));
      const MIN_VALID_SIZE = 0; // Define a constant for the minimum valid size

      const isSelected = isScanTypeSelected("site");
      const isValidNumber = !isNaN(parsedVale) && parsedVale > MIN_VALID_SIZE;
      if (child === "SizeOfSite" && isValidNumber && !isSelected) {
        if (!isScanTypeSelected("exterior")) {
          toggleButtonState("exterior", "scan")();
        }

        toggleButtonState("site", "scan")();
      } else if (
        child === "SizeOfSite" &&
        (isNaN(parsedVale) ||
          parsedVale <= MIN_VALID_SIZE ||
          parsedVale === "") &&
        isSelected
      ) {
        toggleButtonState("exterior", "scan")();
        toggleButtonState("site", "scan")();
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRealEstateScan((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isScanTypeSelected = (type) => {
    return scanType[type] === true;
  };

  const isResidence = () => {
    if (realEstateScan.PropertyInfoScan.PropertyType.length === 0) {
      return true;
    }
    return realEstateScan.PropertyInfoScan.PropertyType[0].name === "residence";
  };

  const toggleButtonState = (value, type) => () => {
    const newType = { id: "", name: value };

    if (type === "property") {
      setPropertyType({
        residence: false,
        commercial: false,
        institutional: false,
        industrial: false,
        [newType.name]: true,
      });

      if (type === "property" && value === "residence") {
        setRealEstateScan((prevState) => ({
          ...prevState,
          BusinessName: "",
          EmailBusiness: "",
        }));
      }

      const updatedRealEstateScan = { ...realEstateScan };
      updatedRealEstateScan.PropertyInfoScan.PropertyType = [newType];
      setRealEstateScan(updatedRealEstateScan);
    } else if (type === "scan") {
      const isSelected = isScanTypeSelected(value);
      if (
        isSelected &&
        value === "interior" &&
        !(isScanTypeSelected("exterior") || isScanTypeSelected("site"))
      ) {
        return;
      } else if (
        isSelected &&
        value === "exterior" &&
        !(isScanTypeSelected("interior") || isScanTypeSelected("site"))
      ) {
        return;
      } else if (isScanTypeSelected("site") && value === "exterior") {
        return;
      }
      setRealEstateScan((prevState) => {
        // Check if PropertyInfoScan exists
        const propertyInfoScan = prevState.PropertyInfoScan || {};

        // Ensure ScanningType is an array
        const scanningType = Array.isArray(propertyInfoScan.ScanningType)
          ? propertyInfoScan.ScanningType
          : [];

        // Check if newType is already in the array and remove it if selected
        const updatedScanningType = isSelected
          ? scanningType.filter((type) => type.name !== value)
          : [...scanningType, newType];

        // Return updated state
        return {
          ...prevState,
          PropertyInfoScan: {
            ...propertyInfoScan,
            ScanningType: updatedScanningType,
          },
        };
      });
    }
    setScanType((prevState) => ({ ...prevState, [value]: !prevState[value] }));
  };

  const ToggleButton = ({ label, selected, fieldName, onClick }) => (
    <Button
      name={fieldName}
      value={realEstateScan[fieldName]}
      onChange={handleInputChange}
      onClick={onClick}
      sx={{
        marginBottom: 1.5,
        width: { xs: 300, md: 400 },
        borderRadius: "12px",
        fontFamily: "Arial, Helvetica, sans-serif",
        backgroundColor: selected ? "#163D6C" : "white",
        color: selected ? "white" : "#404040",
        border: "0.5px solid #a6a6a6",
        "&:hover": {
          backgroundColor: selected ? "#163D6C" : "",
        },
      }}
    >
      <Typography
        sx={{
          textTransform: "none",
          fontSize: "12px",
          padding: "7px",
          fontWeight: 500,
        }}
      >
        {label}
      </Typography>
    </Button>
  );

  function shouldDisableSpecificDates(date) {
    return disabledDates?.some((disabledDate) =>
      dayjs(date).isSame(disabledDate.disabledDate, "day")
    );
  }

  // Function to handle validation logic for SizeOfSite
  const sizeOfSiteValidator = (
    sizeOfSite,
    validatorWithMaxValue,
    noValidator
  ) => {
    // Check if sizeOfSite is a number and greater than 0
    const isValid = !isNaN(sizeOfSite) && sizeOfSite > 0 && sizeOfSite !== null;

    // Return appropriate validator based on validation
    return isValid ? validatorWithMaxValue : noValidator;
  };

  const { t, i18n } = useTranslation();

  return (
    <div className="container-scan">
      <div>
        <div className="container-scan-content">
          <div>
            <img
              className="img-scan"
              src={ScanBannerImage}
              alt="scan-banner-image"
            />
          </div>
          <div className="btnDownload-container">
          <IconButton
            variant="outlined"
            color="inherit"
            sx={{
              color: "#1a477f",
              "&:hover": {
                backgroundColor: "transparent", // Remove hover background color
              },
              "&:active": {
                backgroundColor: "transparent", // Remove click background color
                boxShadow: "none", // Remove click shadow
              },
              "&:focus": {
                backgroundColor: "transparent", // Remove focus background color
                boxShadow: "none", // Remove focus shadow
              },
              "&.MuiButtonBase-root": {
                transition: "none", // Remove transition effects
              },
            }}
          >
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/spreadsheets/d/120ry99oKV2L_Y70jbWjs-kRzZvNscdG-ykdpTiLQfF8/edit?usp=drivesdk"
            >
              <div className="btn-download-content">
                <img
                  className="digital-download-icon"
                  style={{ width: "18px", marginRight: "3px" }}
                  src={excelIcon}
                  alt="download-icon"
                />
                <span className="download-txt">
                  INFO
                </span>
              </div>{" "}
            </a>
          </IconButton>
        </div>
          <div>
            <FormControl
              sx={{ width: { xs: "300px", md: "400px" } }}
              size="small"
            >
              <InputLabel
                sx={{
                  fontSize: "11px",
                  padding: "8px",
                  fontWeight: "600",
                  marginLeft: "-0px",
                  textAlign: "left",
                }}
                id="countryId"
              >
                {t("scan.selectCountry")}
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="countryId"
                fullWidth
                name="Country"
                value={realEstateScan.PropertyInfoScan.Address.Country || ""}
                label={t("scan.selectCountry")}
                onChange={handleSelectChange}
                sx={{
                  padding: "2px", // Add padding
                  borderRadius: "12px", // Add border radius
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px", // Ensure the border radius is applied to the outlined input
                  },
                }}
              >
                <MenuItem value="Albania">
                  {" "}
                  {t("scan.countries.albania")}
                </MenuItem>
                <MenuItem value="Kosovo">
                  {" "}
                  {t("scan.countries.kosovo")}
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          <div>
            <FormControl
              sx={{ width: { xs: "300px", md: "400px" }, marginBottom: "14px" }}
              size="small"
            >
              <InputLabel
                sx={{
                  fontSize: "11px",
                  padding: "8px",
                  marginLeft: "-0px",
                  fontWeight: "600",
                }}
                id="countryId"
              >
                {realEstateScan.PropertyInfoScan.Address.Country !== ""
                  ? t("scan.selectCity")
                  : ""}
              </InputLabel>
              {realEstateScan.PropertyInfoScan.Address.Country === "" ? (
                ""
              ) : (
                <Select
                  labelId="demo-select-small-label"
                  id="cityId"
                  fullWidth
                  name="City"
                  value={realEstateScan.PropertyInfoScan.Address.City}
                  label={t("scan.selectCity")}
                  onChange={handleSelectChange}
                  sx={{
                    padding: "3px",
                    borderRadius: "12px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                    },
                  }}
                >
                  {realEstateScan.PropertyInfoScan.Address.Country === "Albania"
                    ? [
                        <MenuItem key={10} value="Tirane">
                          Tirane
                        </MenuItem>,
                        <MenuItem key={11} value="Elbasan">
                          Elbasan
                        </MenuItem>,
                        <MenuItem key={12} value="Vlore">
                          Vlore
                        </MenuItem>,
                        <MenuItem key={13} value="Korce">
                          Korce
                        </MenuItem>,
                        <MenuItem key={14} value="Diber">
                          Diber
                        </MenuItem>,
                        <MenuItem key={15} value="Kukes">
                          Kukes
                        </MenuItem>,
                        <MenuItem key={16} value="Durres">
                          Durres
                        </MenuItem>,
                        <MenuItem key={17} value="Shkoder">
                          Shkoder
                        </MenuItem>,
                        <MenuItem key={18} value="Fier">
                          Fier
                        </MenuItem>,
                        <MenuItem key={19} value="Lezhe">
                          Lezhe
                        </MenuItem>,
                        <MenuItem key={20} value="Berat">
                          Berat
                        </MenuItem>,
                        <MenuItem key={21} value="Gjirokaster">
                          Gjirokaster
                        </MenuItem>,
                      ]
                    : [
                        <MenuItem key={30} value="Ferizaj">
                          Ferizaj
                        </MenuItem>,
                        <MenuItem key={31} value="Gjakove">
                          Gjakove
                        </MenuItem>,
                        <MenuItem key={32} value="Gjilan">
                          Gjilan
                        </MenuItem>,
                        <MenuItem key={33} value="Mitrovice">
                          Mitrovice
                        </MenuItem>,
                        <MenuItem key={34} value="Peje">
                          Peje
                        </MenuItem>,
                        <MenuItem key={35} value="Prishtine">
                          Prishtine
                        </MenuItem>,
                        <MenuItem key={36} value="Prizren">
                          Prizren
                        </MenuItem>,
                      ]}
                </Select>
              )}
            </FormControl>

            <div>
              <ValidatedTextField
                label={t("scan.sizeOfSpace")}
                type="number"
                value={realEstateScan?.PropertyInfoScan?.SizeOfSpace}
                parent="PropertyInfoScan"
                fieldName="SizeOfSpace"
                tooltip={t("scan.tooltip1")}
                maxValue={
                  realEstateScan?.PropertyInfoScan.Address.Country == "Albania"
                    ? 30000
                    : 1000
                }
                validator={validatorWithMaxValue}
                onChange={handleNestedInputChange}
              />
            </div>
            <div>
              <ValidatedTextField
                label={t("scan.sizeOfSite")}
                type="number"
                value={realEstateScan?.PropertyInfoScan?.SizeOfSite}
                parent="PropertyInfoScan"
                fieldName="SizeOfSite"
                tooltip={t("scan.tooltip1")}
                optional={true}
                maxValue={
                  realEstateScan?.PropertyInfoScan.Address.Country == "Albania"
                    ? 30000
                    : 1000
                }
                validator={validatorWithMaxValue}
                onChange={handleNestedInputChange}
              />
            </div>
          </div>

          <div>
            <div style={{alignSelf:'flex-start'}}>
              <p className="type-title">{t("scan.selectPropertyType")}</p>
            </div>
            {realEstateScan?.PropertyInfoScan.Address.Country !== "Kosovo" && (
              <div>
                <ToggleButton
                  label={t("scan.propertyType.residential")}
                  selected={propertyType.residence}
                  onClick={toggleButtonState("residence", "property")}
                />
              </div>
            )}

            <div>
              <ToggleButton
                label={t("scan.propertyType.commercial")}
                selected={propertyType.commercial}
                onClick={toggleButtonState("commercial", "property")}
              />
            </div>
            <div>
              <ToggleButton
                label={t("scan.propertyType.institutional")}
                selected={propertyType.institutional}
                onClick={toggleButtonState("institutional", "property")}
              />
            </div>
            <div>
              <ToggleButton
                label={t("scan.propertyType.industrial")}
                selected={propertyType.industrial}
                onClick={toggleButtonState("industrial", "property")}
              />
            </div>
          </div>
          <div className="scanType-cont">
            <div>
              <p className="type-title">{t("scan.selectScanType")}</p>
            </div>
            <div>
              <ToggleButton
                label={t("scan.scanType.interior")}
                selected={scanType.interior}
                onClick={toggleButtonState("interior", "scan")}
              />
            </div>
            <div>
              <ToggleButton
                label={t("scan.scanType.exterior")}
                selected={scanType.exterior}
                onClick={toggleButtonState("exterior", "scan")}
              />
            </div>
            <div>
              <ToggleButton
                label={t("scan.scanType.site")}
                selected={scanType.site}
                // onClick={toggleButtonState('site', 'scan')}
              />
            </div>
            <div>
              {/* {textFieldCustom2(
                t('scan.scheduleMeetingLabel'),
                "datetime",
                ClockIcon
              )} */}
            </div>
          </div>

          {/* <div>
          <div >
              <p className="type-title">{t('scan.scheduleMeetingLabel')}</p>
            </div>
            <CalendlyWidget 
            scan={true}
            />
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}
                adapterLocale={i18n.language === "en" ? "en" : "sq"}
              >
                <div style={{ width: isSmallScreen ? "305px" : "100%" }}>
                  <StaticDateTimePicker
                    orientation={isSmallScreen ? "portrait" : "landscape"}
                    value={selectedDateTime}
                    onChange={handleDateChange}
                    minDateTime={dayjs()} // Prevent selecting past dates
                    shouldDisableDate={shouldDisableSpecificDates}
                    ampm={false} // Use 24-hour format
                    minutesStep={1} // Allow minute selection in steps of 1
                    sx={{ width: "100%", backgroundColor: "transparent" }}
                    onAccept={(date) => {
                     
                      // Your custom logic here
                    }}
                    onCancel={() => {
                     
                      // Your custom logic here
                    }}
                  />
                </div>
              </LocalizationProvider>
            </Box>
          </div> */}
        </div>

        <div className="scan-file calendarTop">
          <div className="calendar-top">
          <p className="type-title">{t("scan.scanFileQuestion")}</p>
          </div>
          <br />
          <div>
            <form className="form-container">
              <div className="input-group">
                <input
                  className="custom-radio"
                  type="radio"
                  id="option1"
                  name="options"
                  value="Yes"
                  checked={realEstateScan?.ScanFile}
                  onChange={
                    realEstateScan?.PropertyInfoScan?.Address?.Country ===
                      "Albania" && handleScanFileChange
                  }
                />
                <label htmlFor="option1">{t("scan.yes")}</label>
              </div>
              <div className="input-group">
                <input
                  className="custom-radio"
                  type="radio"
                  id="option2"
                  name="options"
                  value="No"
                  checked={!realEstateScan?.ScanFile}
                  onChange={
                     handleScanFileChange
                  }
                />
                <label htmlFor="option2">{t("scan.no")}</label>
              </div>
            </form>
          </div>
        </div>

        {!isResidence() && (
          <div className="google-map">
            <div>
            <p className="type-title">{t("scan.googleMapQuestion")}</p>
            </div>
            <br />
            <div>
              <form className="form-container">
                <div className="input-group">
                  <input
                    className="custom-radio"
                    type="radio"
                    id="option1"
                    name="googleMap"
                    value="Yes"
                    checked={
                      realEstateScan?.PropertyInfoScan.Address.Country ===
                        "Kosovo" ||
                      (realEstateScan.GoogleMap
                        ? realEstateScan?.PropertyInfoScan.Address.Country ===
                          "Albania"
                        : undefined)
                    }
                    onChange={handleOptionChange}
                  />
                  <label htmlFor="option1">{t("scan.yes")}</label>
                </div>
                <div className="input-group">
                  <input
                    className="custom-radio"
                    type="radio"
                    id="option2"
                    name="googleMap"
                    value="No"
                    checked={!realEstateScan?.GoogleMap}
                    onChange={
                      realEstateScan?.PropertyInfoScan.Address.Country === "Albania" ? handleOptionChange : undefined
                    }
                  />
                  <label htmlFor="option2">{t("scan.no")}</label>
                </div>
              </form>
            </div>
          </div>
        )}
        {realEstateScan.GoogleMap === true && (
          <div className="business-info">
            <ValidatedTextField
              label={t("scan.emailLabelBussines")}
              type="email"
              parent="Email"
              fieldName="EmailBusiness"
              showAdornment={false}
              validator={
                realEstateScan.PropertyInfoScan.PropertyType[0].name ===
                "residence"
                  ? noValidator
                  : emailValidator
              }
              onChange={handleNestedInputChange}
            />
            <ValidatedTextField
              label={t("scan.businessNameLabel")}
              type="text"
              parent="BusinessName"
              fieldName="BusinessName"
              showAdornment={false}
              validator={
                realEstateScan.PropertyInfoScan.PropertyType[0].name ===
                "residence"
                  ? noValidator
                  : requiredValidator
              }
              onChange={handleNestedInputChange}
            />
          </div>
        )}
        <div className="site-contact">
          <Box 
            sx={{alignSelf:'center', width:'400px',
           
            }}
          >
          <Typography
            variant="h3"
            sx={{
              alignSelf: "flex-start",
              marginLeft: { xs: "50px", md: "0px" },
              marginBottom: 1,
              color: "#1e5394",
              fontSize: "13px",
              width:'400px',
              fontWeight: 600,
              wordWrap: "break-word", // Allows long words to be broken and wrap to the next line
              overflowWrap: "break-word", // Additional property for older browsers
              whiteSpace: "pre-wrap", // Allows text to wrap naturally
              overflow: "hidden", // Hides overflow content
              textOverflow: "ellipsis", // Adds ellipsis (...) when text overflows
            }}
          >
            {t("scan.siteContact.question")}
          </Typography>
          </Box>
          
          <br />
          <ValidatedTextField
            label={t("scan.siteContact.nameLabel")}
            type="text"
            parent="SiteContact"
            fieldName="Name"
            showAdornment={false}
            validator={textValidator}
            onChange={handleNestedInputChange}
          />

          <ValidatedTextField
            label={t("scan.siteContact.emailLabel")}
            type="text"
            parent="SiteContact"
            fieldName="Email"
            showAdornment={false}
            validator={emailValidator}
            onChange={handleNestedInputChange}
          />

          <ValidatedTextField
            label={t("scan.siteContact.phoneLabel")}
            type="text"
            parent="SiteContact"
            fieldName="PhoneNumber"
            showAdornment={false}
            validator={phoneNumberValidator}
            onChange={handleNestedInputChange}
          />
          <TextField
            label={t("scan.siteContact.descriptionLabel")}
            id="description"
            multiline
            minRows={6}
            onChange={(e) =>
              handleNestedInputChange(e, "PropertyInfoScan", "Description")
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                fontSize: "12px",
                padding: "13px",
              }, // Adjust border radius here
              width: { xs: "300px", md: "400px" }, // Adjust width based on screen size
            }}
            InputProps={{
              fontSize: "12px",
              padding: "14px",
            }}
            InputLabelProps={{
              sx: {
                fontSize: "11px",
                fontWeight: "600",
                fontFamily: "Roboto, sans-serif",
                padding: "8px",
                marginLeft: "-3px",
              },
            }}
            style={{ marginBottom: 16, fontSize: "12px" }}
          />
        </div>
        <div className="propInfo-Container">
          <PropertyInfoScan
            realEstateScan={realEstateScan}
            setRealEstateScan={setRealEstateScan}
          />
        </div>
      </div>
      <div>
        <PricingCard
          realEstateScan={realEstateScan}
          setRealEstateScan={setRealEstateScan}
          onFieldErrors={handleFieldsError}
        />
      </div>
    </div>
  );
}
