import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { maxCharactersValidator } from '../../../Validators/validator';


const FieldRegister = ({ label, type, fieldName, parent, validator,maxCharacters = 0,
                          value,   onChange, icon , errorForm}) => {
  const [error, setError] = useState("");

  const handleChange = e => {
    let errorMessage = '';
    const newValue = e.target.value;
  
    if (maxCharacters > 0) {
      errorMessage = maxCharactersValidator(newValue, maxCharacters);
    }
  
    const additionalError = validator(newValue);
    if (additionalError) {
      errorMessage += (errorMessage ? '<br />' : '') + additionalError;
    }
  
    setError(errorMessage);
    onChange(e, parent, fieldName);
  };
  
  return (

  <TextField
    placeholder={label}
    variant="outlined"
    size='small'
    value={value}
    helperText={<span dangerouslySetInnerHTML={{ __html: error }} />}
    error={!!error}
    name={fieldName}
    onChange={handleChange}
    type={type}
    fullWidth
    sx={{
      '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        fontSize: '12px',
        padding: '6px'
      },
      width: { xs: '300px', md: '400px' },
      '& .MuiInputBase-input::placeholder': {
        color: 'black',
      }
    }}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <img style={{ marginLeft: '10px', width: '15px' }} src={icon} alt='icon' />
        </InputAdornment>
      ),
    }}
    InputLabelProps={{
      sx: {
        fontSize: '11px',
        fontWeight: '600',
        fontFamily: 'Roboto, sans-serif',
        padding: '8px',
        marginLeft: '-3px',
        shrink: true,
      },
    }}
    style={{ marginBottom: 16, fontSize: '12px' }}
  
/>

);
};

export default FieldRegister;