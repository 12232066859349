import React from "react";
import { Link } from "react-router-dom";
import DoneIcon from '../../assets/Icons/Done.png';
import Logo from '../../assets/Images/Logo.png';
import { Button } from "@mui/material";
import { useTranslation } from 'react-i18next';

export default function PasswordConfirmed() {
    const { t } = useTranslation();

    return (
        <div className="reset-link-sent-cont">
            <div className="reset-link-content">
                <div>
                    <img className="logo-top" src={Logo} alt="logo-img" />
                </div>
                <div className="reset-link-form">
                    <img src={DoneIcon} alt="done-icon" />
                    <h3>{t('passwordConfirmed.title')}</h3>
                    
                    <Link
                        className='link'
                        to='/login'
                    >
                        <Button
                            sx={{
                                padding: '8px',
                                fontSize: '12px',
                                fontWeight: 400,
                                borderRadius: '10px',
                                marginTop: '-5px',
                                border: 'none',
                                backgroundColor: '#1a477f',
                                color: 'white',
                                cursor: 'pointer',
                                textTransform: 'none',
                                width: '300px',
                                '&:hover': {
                                    backgroundColor: '#163D6C',
                                }
                            }}
                        >
                            {t('passwordConfirmed.buttonText')}
                        </Button>
                    </Link>
                </div>
                <div className="terms-cont">
                    <p className="p-terms">
                        <Link
                            className="link"
                            to='/terms-Condition'
                        >
                            <span>{t('passwordConfirmed.termsOfUse')}</span>
                        </Link>
                        <span> &nbsp; | &nbsp; </span>
                        <Link
                            className="link"
                            to='/privacy-policy'
                        >
                            <span>{t('passwordConfirmed.privacyPolicy')}</span>
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
}
