import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  Chip,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Link,
  Tooltip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  getCurrentUser,
  subscribCheckout,
  getUserActiveSubscriptions,
  connectProjectsWithSubsDigital,
  getDigitalProjectsByUserId,
  notifyProjectConnectedWithSubscription
} from "../../API/apiService";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SubscriptionOptions from "./SubscriptionOptions";

const theme = createTheme({
  palette: {
    primary: {
      main: "#163D6C",
    },
  },
});

const ChooseSubsPlanScan = () => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modified, setModified] = useState(false);
  const [activeSubscription, setActiveSubscriptions] = useState({});
  const [projectsWithoutSubscription, setProjectsWithoutSubscription] =
    useState([]);
  const [projectsWithSubscription, setProjectsWithSubscription] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (getCurrentUser() === null) {
      navigate("/login");
      return;
    }

    const projectsScan = async () => {
      try {
        setLoading(true);
        const result = await getDigitalProjectsByUserId(getCurrentUser()["id"]);
        const activeSubscriptions = await getUserActiveSubscriptions();
        const scanSubs = activeSubscriptions.data?.filter(
          (s) => s.type === "digital" && s.isActive === true
        );

        setActiveSubscriptions(scanSubs);

        if (result.success === true) {
          const projectsOutOfsubsciption = result.data?.filter(
            (p) => p.subscriptionId === null
          );
          const projectsWithSubsciption = result.data?.filter(
            (p) => p.subscriptionId !== null
          );
  
          setProjectsWithoutSubscription(projectsOutOfsubsciption);
          setProjectsWithSubscription(projectsWithSubsciption);
        }
      } catch (error) {
        navigate("/server-error");
      } finally {
        setLoading(false);
      }
    };

    projectsScan();
  }, [modified]);

  const handleProjectChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProjects(value);
  };

  const getNumberOfProjectsSubsOffer = (totalNumber) => {
    if (totalNumber > 0 && totalNumber <= 3) {
      return { nrOfProjects: "1 - 3", planName: "s1" };
    } else if (totalNumber > 3 && totalNumber <= 10) {
      return { nrOfProjects: "4 - 10", planName: "s2" };
    } else if (totalNumber > 10 && totalNumber <= 20) {
      return { nrOfProjects: "11 - 20", planName: "s3" };
    } else if (totalNumber > 20 && totalNumber <= 40) {
      return { nrOfProjects: "21 - 40", planName: "s4" };
    }
  };

  const getSubsPriceByNumberOfProject = (totalNumber) => {
    if (totalNumber > 0 && totalNumber <= 3) {
      return "16.5";
    } else if (totalNumber > 3 && totalNumber <= 10) {
      return "50";
    } else if (totalNumber > 10 && totalNumber <= 20) {
      return "90";
    } else if (totalNumber > 20 && totalNumber <= 40) {
      return "160";
    }
  };

  const getNrOfProjectsByPlanName = (planName) => {
    if (planName === "s1") {
      return { nrOfProjects: "1 - 3", price: "16.5" };
    } else if (planName === "s2") {
      return { nrOfProjects: "4 - 10", price: "50" };
    } else if (planName === "s3") {
      return { nrOfProjects: "11 - 20", price: "90" };
    } else if (planName === "s4") {
      return { nrOfProjects: "21 - 40", price: "160" };
    }
  };

  const getMaxNrOfProjectsByPlanName = (planName) => {
    if (planName === "d1") {
      return 3;
    } else if (planName === "d2") {
      return 10;
    } else if (planName === "d3") {
      return 20;
    } else if (planName === "d4") {
      return 40;
    }
  };
  const handleSubscribe = async (e, subsType) => {
    e.preventDefault();
    if (!subsType) {
      toast.error(t("subscription.refreshError"));
      return;
    }
    const userLogged = getCurrentUser();
    if (userLogged === null) {
      toast.error(t("subscription.loginError"));
      navigate("/login");
      return;
    }

    try {
      const payload = {
        userId: getCurrentUser()["id"],
        serviceType: subsType,
        projectIds:
          activeSubscription[0]?.isActive === true ? [""] : selectedProjects,
        planType:
          activeSubscription?.length > 0 && activeSubscription[0]?.isActive
            ? activeSubscription[0].planType
            : getNumberOfProjectsSubsOffer(selectedProjects.length).planName,
      };
  
      const response = await subscribCheckout(payload);
  
      if (response.success) {
        window.location.href = response.data;
      }
    } catch (error) {}
  };

  const handleConnectProjectWithSubscription = async () => {
    if (selectedProjects.length === 0) {
      toast.error(t('subscription.selectProject'));
      return;
    }
    if (checkIfProjectCanConnectedWithSubscription() === false) {
     
      toast.error(t('subscription.canNotConnectMessage'));
      return;
    }
   
    if (activeSubscription && selectedProjects.length > 0) {
      try {
        const response = await connectProjectsWithSubsDigital(
          activeSubscription[0].id,
          selectedProjects
        );
        if (response.success) {
          const subsDto = {
            projectIds: selectedProjects,
            userId: getCurrentUser()["id"],
            serviceType: "digital",
            planType:  activeSubscription[0].planType
          }
          toast.success(t('subscription.projectConnected'));
          await notifyProjectConnectedWithSubscription(subsDto);
         
        }
      
      } catch (error) {
      } finally {
        setSelectedProjects([]);
        setModified(!modified);
      }
    }
  };

  const checkIfProjectCanConnectedWithSubscription = () => {
    const maxNrOfProjectsOfferedByPlan = getMaxNrOfProjectsByPlanName(
      activeSubscription[0].planType
    );
   
    const projectsConnectedWithSubscription =
      projectsWithSubscription.length + selectedProjects.length;

    if (projectsConnectedWithSubscription > maxNrOfProjectsOfferedByPlan) {
      return false;
    }

    return true;
  };

  const SubscriptionCard = ({
    title,
    price,
    details,
    subsType,
    numberOfProjects,
    isActive = false,
  }) => (
    <Card
      variant="outlined"
      sx={{
        maxWidth: "100%",
        boxShadow: 4,
        borderRadius: 3,
        m: 2,
        p: { xs: 2, sm: 5 },
        boxSizing: "border-box",
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{
            wordWrap: "break-word",
            textAlign: "center",
            overflowWrap: "break-word",
          }}
        >
          {title}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <MonetizationOnIcon sx={{ mt: 2 }} color="primary" fontSize="large" />
          <Typography
            variant="h4"
            color="primary"
            sx={{ ml: 1, wordWrap: "break-word", overflowWrap: "break-word" }}
          >
            ${price}/ {t("subscription.month")}
          </Typography>
        </Box>
        <List sx={{ paddingRight: { xs: 0, sm: 2 }, textAlign: "start" }}>
          {details.map((detail, index) => (
            <ListItem
              key={index}
              disableGutters
              sx={{ display: "flex", alignItems: "flex-start" }}
            >
              <ListItemIcon>
                <CheckCircleIcon sx={{ mt: 1, ml: -0 }} color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={detail}
                sx={{
                  paddingRight: { xs: 20, md: 10, lg: 0 },
                  textAlign: "start",
                  marginLeft: "-15px",
                  marginTop: 0.5,
                }}
              />
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          sx={{ mt: 2 }}
          onClick={(e) => handleSubscribe(e, subsType)}
        >
          {isActive
            ? t("subscription.activePlan")
            : t("subscription.subscribeNow")}
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: "2rem", minHeight: "100vh", mt: 1 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontSize: { xs: "18px", md: "24px" } }}
        >
           {t('subscription.includeProjectInSubsPlan')}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "40px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <FormControl sx={{ width: { xs: "100%", md: "30%" } }}>
              <InputLabel id="project-select-label">
              {t('subscription.yourProjects')}
              </InputLabel>
              <Select
                labelId="project-select-label"
                multiple
                disabled={activeSubscription.length === 0}
                value={selectedProjects}
                onChange={handleProjectChange}
                input={<OutlinedInput label= {t('subscription.yourProjects')}/>}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          projectsWithoutSubscription.find(
                            (proj) => proj.projectId === value
                          )?.projectName
                        }
                      />
                    ))}
                  </Box>
                )}
              >
                {projectsWithoutSubscription.map((project) => (
                  <MenuItem key={project.projectId} value={project.projectId}>
                    {project.projectName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {activeSubscription.length > 0 && (
              <Button
                onClick={() => handleConnectProjectWithSubscription()}
                variant="outlined"
                color="primary"
                sx={{ textTransform: "none", maxHeight: 55, minWidth: 80 }}
                disabled={selectedProjects.length === 0}
              >
               {t('subscription.addProjects')}
              </Button>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 20,
            mt: 10,
            flexDirection: {
              xs: "column", // Stack elements vertically on small screens
              md: "row", // Align elements in a row on medium and up
            },
          }}
        >
          {/* Subscription Cards */}
          {/* {activeSubscription.length > 0 && activeSubscription[0].isActive && (
            <Box>
              <SubscriptionCard
                title={t("subscription.scanningTitle")}
                price={
                  getNrOfProjectsByPlanName(activeSubscription[0].planType)
                    .price
                }
                subsType="scan"
                isActive={true}
                details={[
                  `Number of projects: ${
                    getNrOfProjectsByPlanName(activeSubscription[0].planType)
                      .nrOfProjects
                  }`,
                  t("subscription.scanningDetail1"),
                  t("subscription.scanningDetail2"),
                ]}
              />
            </Box>
          )}

          {activeSubscription.length === 0 && selectedProjects.length > 0 && (
            <Box>
              <SubscriptionCard
                title={t("subscription.scanningTitle")}
                price={getSubsPriceByNumberOfProject(selectedProjects.length)}
                subsType="scan"
                isActive={false}
                details={[
                  `Number of projects: ${
                    getNumberOfProjectsSubsOffer(selectedProjects.length)
                      .nrOfProjects
                  }`,
                  t("subscription.scanningDetail1"),
                  t("subscription.scanningDetail2"),
                ]}
              />
            </Box>
          )} */}
          {projectsWithSubscription?.length > 0 && (
            <Box>
              <Typography sx={{ mb: 1 }}>
              {t('subscription.includedProjects')}
              </Typography>
              <TableContainer
                component={Paper}
                style={{ maxHeight: 400, minWidth: 200, overflowY: "auto" }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>{t('subscription.projectName')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectsWithSubscription.map((project, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{project.projectName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {activeSubscription.isActive &&
            projectsWithSubscription?.length === 0 && (
              <Box>
                <Typography sx={{ mb: 1 }}>
                {t('subscription.includedProjects')}
                </Typography>
                <Typography sx={{ fontSize: 13, color: "grey" }}>
                <TableCell>{t('subscription.noProjectIncluded')}</TableCell>
                </Typography>
              </Box>
            )}
          {activeSubscription.length === 0 &&
            projectsWithSubscription?.length === 0 && (
              <Box>
                <Typography sx={{ mb: 1 , fontSize:16}}>
                {t('subscription.noDigitalPlanActive')}
                </Typography>
                {/* <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                   navigate("/subscription-options")
                  }}
                >
                   {t('subscription.startHere')}
                </Link> */}
              </Box>
            )}
        </Box>
        <SubscriptionOptions
          serviceType="digital"
        />
      </Box>
    </ThemeProvider>
  );
};

export default ChooseSubsPlanScan;
